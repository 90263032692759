import {client} from '../index';

export const getAllShopDetailsQuery = (handle = null) => {
  const filter = handle ? `${handle}*` : null;
  const productFilter = handle ? `vendor:${handle} OR product_type:'Optional Extra'` : null; 
  const query = client.query((root) => {
      root.addConnection('products', {args: {first: 250, query: productFilter }}, (product) =>{ 
        product.add('title');
        product.add('id');
        product.add('description');
        product.add('productType');
        product.add('vendor');
        product.add('tags');
        product.add('handle');
        product.addConnection('images', {args: {first: 250}}, (images) => {
          images.add('src');
          images.add('id');
          images.add('altText');
        });
        product.addConnection('variants', {args: {first: 250}}, (variants) => {
          variants.add('id');
          variants.add('product');
          variants.add('title');
          variants.add('price',(price)=>{
            price.add('amount');
            price.add('currencyCode');
          });
          variants.add('availableForSale');
          variants.add('image', (image) => {
            image.add('src');
            image.add('id');
            image.add('altText');
          });
        });
      });
      root.addConnection('collections', {args: {first: 250, query: filter}}, (collection) => {
        collection.add('title');
        collection.add('handle');
      });
  });
  return query;
};

export const getCustomerAccessTokenMutation = (email, password) => {
  const credentials = {
    'email': email,
    'password': password,
  };
  const input = client.variable(credentials, 'CustomerAccessTokenCreateInput!');
  const mutation = client.mutation('customerAccessTokenCreate', input,
    (root) => {
      root.add('customerAccessTokenCreate', {args: {input: credentials}},
        (customerAccessTokenCreate) => {
          customerAccessTokenCreate.add('customerAccessToken',
            (customerAccessToken) => {
              customerAccessToken.add('accessToken');
              customerAccessToken.add('expiresAt');
            });
        });
    });
  return mutation;
};

export const getCustomerAccessTokenDeleteMutation = (token) => {
    const mutation = client.mutation((root) => {
      root.add('customerAccessTokenDelete', { args: { "customerAccessToken": token } }, (customerAccessTokenDelete) => {
        customerAccessTokenDelete.add('deletedAccessToken');
        customerAccessTokenDelete.add('userErrors', (userErrors) => {
          userErrors.add('field');
          userErrors.add('message');
        });
      });
    });
    return mutation;
};

export const getCustomerQuery = (token) => {
  const query = client.query((root) => {
    root.add('customer', {args: {customerAccessToken: token}}, (customer) => {
      customer.add('id');
      customer.add('displayName');
      customer.add('email');
      customer.add('firstName');
      customer.add('lastName');
      customer.add('defaultAddress', (defaultAddress) => {
        defaultAddress.add('address1');
        defaultAddress.add('address2');
        defaultAddress.add('city');
        defaultAddress.add('company');
        defaultAddress.add('country');
        defaultAddress.add('firstName');
        defaultAddress.add('lastName');
        defaultAddress.add('phone');
        defaultAddress.add('province');
        defaultAddress.add('zip');
      });
      customer.addConnection('addresses', {args: {first: 100}}, (address) => {
        address.add('address1');
        address.add('address2');
        address.add('city');
        address.add('company');
        address.add('country');
        address.add('firstName');
        address.add('lastName');
        address.add('phone');
        address.add('province');
        address.add('zip');
      });
      // customer.addConnection('orders', {args: {first: 100}}, (order) => {
      //   order.add('id');
      //   order.add('orderNumber');
      //   order.add('name');
      //   order.add('processedAt');
      //   order.add('fulfillmentStatus');
      //   order.add('totalPriceV2', (totalPriceV2) => {
      //     totalPriceV2.add('amount');
      //     totalPriceV2.add('currencyCode');
      //   });
      //   order.addConnection('lineItems', {args: {first: 100}}, (lineItems) => {
      //     lineItems.add('currentQuantity');
      //     lineItems.add('customAttributes', (customAttribute) => {
      //       customAttribute.add('key');
      //       customAttribute.add('value');
      //     });
      //     lineItems.add('originalTotalPrice', (price) => {
      //       price.add('amount');
      //       price.add('currencyCode');
      //     });
      //     lineItems.add('variant', (variant) => {
      //       variant.add('id');
      //       variant.add('sku');
      //       variant.add('available');
      //       variant.add('title');
      //       variant.add('selectedOptions', (selectedOption) => {
      //         selectedOption.add('name');
      //         selectedOption.add('value');
      //       });
      //       variant.add('image', (image) => {
      //         image.add('id');
      //         image.add('src');
      //         image.add('altText');
      //       });
      //       variant.add('product', (product) => {
      //         product.add('id');
      //         product.add('title');
      //         product.add('handle');
      //       });
      //     });
      //   });
      // });
      customer.add('tags');
      // customer.add('metafields');
    });
  });
  return query;
};

export const getCustomerOrderByIdQuery = (id) => {
  const query = client.query((root) => {
    root.add('node', {args: {id}}, (node) => {
      node.addInlineFragmentOn('Order', (order) => {
        order.add('orderNumber');
        order.add('name');
        order.add('processedAt');
        order.add('fulfillmentStatus');
        order.add('subtotalPrice', (subtotalPrice) => {
          subtotalPrice.add('amount');
          subtotalPrice.add('currencyCode');
        });
        order.add('totalPrice', (totalPrice) => {
          totalPrice.add('amount');
          totalPrice.add('currencyCode');
        });
        order.add('totalTax', (totalTax) => {
          totalTax.add('amount');
          totalTax.add('currencyCode');
        });
        order.add('totalShippingPriceV2', (totalShippingPriceV2) => {
          totalShippingPriceV2.add('amount');
          totalShippingPriceV2.add('currencyCode');
        });
        order.add('currencyCode');
        order.add('id');
        order.add('shippingAddress', (shippingAddress) => {
          shippingAddress.add('address1');
          shippingAddress.add('address2');
          shippingAddress.add('city');
          shippingAddress.add('company');
          shippingAddress.add('country');
          shippingAddress.add('firstName');
          shippingAddress.add('lastName');
          shippingAddress.add('phone');
          shippingAddress.add('province');
          shippingAddress.add('zip');
        });
        // order.addConnection('discountApplications', {args: {first: 1}}, (discountApplications) => {
        //   discountApplications.add('value');
        // });
        order.addConnection('lineItems', {args: {first: 100}}, (lineItem) => {
          lineItem.add('currentQuantity');
          lineItem.add('title');
          lineItem.add('originalTotalPrice', (originalTotalPrice) => {
            originalTotalPrice.add('amount');
            originalTotalPrice.add('currencyCode');
          });
          lineItem.add('customAttributes', cs => {
            cs.add('key');
            cs.add('value');
          })
          lineItem.add('variant', v =>{
            v.add('id');
            v.add('sku');
            v.add('availableForSale');
            v.add('title');
            v.add('selectedOptions', (selectedOption) => {
              selectedOption.add('name');
              selectedOption.add('value');
            });
            v.add('image', (image) => {
              image.add('src');
              image.add('id');
              image.add('altText');
            });
            v.add('product', (product) => {
              product.add('id');
              product.add('title');
              product.add('handle');
            });
          })
          lineItem.add('discountedTotalPrice', (discountedTotalPrice) => {
            discountedTotalPrice.add('amount');
            discountedTotalPrice.add('currencyCode');
          });
        })
      });
    });
  });
  return query;
};

export const getCustomerOrdersQuery = (token,page) => {
  const query = client.query((root) => {
    root.add('customer', {args: {customerAccessToken: token}}, (customer) => {
      customer.addConnection('orders', {args: {first: page*20,reverse:true}}, (order) => {
        order.add('id');
        order.add('orderNumber');
        order.add('name');
        order.add('processedAt');
        order.add('fulfillmentStatus');
        order.add('totalPrice', (totalPriceV2) => {
          totalPriceV2.add('amount');
          totalPriceV2.add('currencyCode');
        });
        order.addConnection('lineItems', {args: {first: 100}}, (lineItems) => {
          lineItems.add('currentQuantity');
          lineItems.add('customAttributes', (customAttribute) => {
            customAttribute.add('key');
            customAttribute.add('value');
          });
          lineItems.add('originalTotalPrice', (price) => {
            price.add('amount');
            price.add('currencyCode');
          });
          lineItems.add('variant', (variant) => {
            variant.add('id');
            variant.add('sku');
            variant.add('availableForSale');
            variant.add('title');
            variant.add('selectedOptions', (selectedOption) => {
              selectedOption.add('name');
              selectedOption.add('value');
            });
            variant.add('image', (image) => {
              image.add('id');
              image.add('src');
              image.add('altText');
            });
            variant.add('product', (product) => {
              product.add('id');
              product.add('title');
              product.add('handle');
            });
          });
        });
      });
    });
  });
  return query;
};
export const getCheckoutCustomerAssociateMutation = (checkoutId, customerAccessToken) => {
  const mutation = client.mutation((root) => {
    root.add('checkoutCustomerAssociateV2', { args: { "checkoutId": checkoutId, "customerAccessToken": customerAccessToken } }, (checkoutCustomerAssociateV2) => {
      checkoutCustomerAssociateV2.add('checkout', (checkout) => {
          checkout.add('id');
      });
    })
  });
  return mutation;
}
export const getCartBuyerIdentityUpdateMutation = (cartId, buyerIdentityInput) => {
  const mutation = client.mutation((root) => {
    root.add('cartBuyerIdentityUpdate', { args: { "cartId": cartId, "buyerIdentity": buyerIdentityInput } }, (cartBuyerIdentityUpdate) => {
      cartBuyerIdentityUpdate.add('cart', (cart) => {
        cart.add('id');
        cart.add('buyerIdentity',(buyerIdentity)=> {
          buyerIdentity.add('email');
          buyerIdentity.add('phone');
          buyerIdentity.add('countryCode');
          buyerIdentity.add('customer', (customer) =>{
            customer.add('id');
          })
        })
      });
    })
  });
  return mutation;
}
export const getCheckoutCreateMutation = (checkoutFields) => {
  checkoutFields.presentmentCurrencyCode = client.enum(process.env.REACT_APP_CURRENCY || "USD");
    const mutation = client.mutation((root) => {
      root.add('checkoutCreate', { args: { "input": checkoutFields } }, (checkoutCreate) => {
        checkoutCreate.add('checkout', (checkout) => {
            checkout.add('id');
            checkout.add('webUrl');
        });
        checkoutCreate.add('checkoutUserErrors', (checkoutUserErrors) => {
          checkoutUserErrors.add('code');
          checkoutUserErrors.add('field');
          checkoutUserErrors.add('message');
        });
      });
    });
    return mutation;
};

export const getCartCreateMutation = (CartCreateInput) => {
    const mutation = client.mutation((root) => {
      root.add('cartCreate', { args: { "input": CartCreateInput } }, (cartCreate) => {
          cartCreate.add('cart', (cart)=> {
            cart.add('id');
            cart.add('checkoutUrl')
          });
          cartCreate.add('userErrors', (userErrors) => {
            userErrors.add('code');
            userErrors.add('field');
            userErrors.add('message');
          });
      });
    });
    return mutation;
};

export const getAllPagesQuery = () => {
  return client.query((root) => {

    root.addConnection('pages', {args: {first: 25}}, (page) => {
      page.add('title');
      page.add('id');
      page.add('createdAt');
      page.add('handle');
      page.add('updatedAt');
      // page.add('url');
    });

  });
};

export const getPageByHandleQuery = (handle) => {
  return client.query((root) => {
    root.add('pageByHandle', {args: {handle}}, (page) => {
      page.add('body');
      page.add('bodySummary');
      page.add('title');
      page.add('id');
      // page.add('bodySummary');
      page.add('createdAt');
      page.add('handle');
      page.add('updatedAt');
      // page.add('url');
    })
  });
};

export const getProductByHandle = (handle) => {
  const query = client.query((root) => {
    root.add('productByHandle', {args: {handle: handle}}, (product) => {
      product.add('title');
      product.add('id');
      product.add('description');
      product.add('descriptionHtml');
      product.add('productType');
      product.add('vendor');
      product.add('createdAt');
      product.add('handle');
      // product.addConnection('metafields', {args: {identifiers: {namespace: "", key: ""}}}, (metafield) => {
      //   metafield.add('key');
      //   metafield.add('value');
      // });
      product.add('tags');
      product.addConnection('images', {args: {first: 10}}, (images) => {
        images.add('src');
        images.add('id');
        images.add('altText');
      });
      product.addConnection('variants', {args: {first: 250}}, (variants) => {
        variants.add('id');
        variants.add('product');
        variants.add('title');
        variants.add('price',(price)=>{
          price.add('amount');
          price.add('currencyCode');
        });
        variants.add('sku');
        variants.add('availableForSale');
        variants.add('image', (image) => {
          image.add('src');
          image.add('id');
          image.add('altText');
        });
        variants.add('selectedOptions', (selectedOptions) => {
          selectedOptions.add('name');
          selectedOptions.add('value');
        });

      });
    });
  });
  return query;
};

export const getRelatedProducts = (pType, vendor, title) => {
  let pQuery = (pType && vendor && title) ?
    'vendor:' + vendor + ' AND product_type:' + pType + ' NOT title:\'' + title + '\'':
    null;
  const query = client.query((root) => {
      root.addConnection('products', {args: {first: 5, query: pQuery}},
        (product) => {
          product.add('title');
          product.add('id');
          product.add('handle');
          product.add('createdAt');
          product.addConnection('images', {args: {first: 10}}, (images) => {
            images.add('src');
            images.add('id');
            images.add('altText');
          });
          product.addConnection('variants', {args: {first: 250}},
            (variants) => {
              variants.add('id');
              variants.add('product');
              variants.add('title');
              variants.add('price', (price) => {
                price.add('amount');
                price.add('currencyCode');
              });
              variants.add('availableForSale');
              variants.add('image', (image) => {
                image.add('src');
                image.add('id');
                image.add('altText');
              });
            });
        });
    });
  return query;
};

export const getCollectionByHandleQuery = (collectionHandle) => {
  const query = client.query((root) => {
    root.add('collectionByHandle', {args: {handle: collectionHandle}},
      (collection) => {
      collection.add('title');
      collection.add('description');
        collection.addConnection('products', {args: {first: 250}},
          (product) => {
            product.add('title');
            product.add('id');
            product.add('handle');
            product.add('createdAt');
            product.add('tags');
            product.addConnection('images', {args: {first: 5}}, (images) => {
              images.add('src');
              images.add('id');
              images.add('altText');
            });
            product.addConnection('variants', {args: {first: 10}},
              (variants) => {
                variants.add('id');
                variants.add('product');
                variants.add('title');
                variants.add('price',(price)=>{
                  price.add('amount');
                  price.add('currencyCode');
                });
                variants.add('availableForSale');
                variants.add('image', (image) => {
                  image.add('src');
                  image.add('id');
                  image.add('altText');
                });
              });
          });
      });
  });
  return query;
};

export const getCollectionByIdQuery = (id) => {
   const query = client.query((root) => {
    root.add('node', { args: { id } }, (node) => {
      node.addInlineFragmentOn('Collection', (collection) => {
        collection.add('title');
        collection.add('description');
        collection.addConnection('products', { args: { first: 250 } },
          (product) => {
            product.add('title');
            product.add('id');
            product.add('handle');
            product.add('createdAt');
            product.add('tags');
            product.addConnection('images', { args: { first: 5 } }, (images) => {
              images.add('src');
              images.add('id');
              images.add('altText');
            });
            product.addConnection('variants', { args: { first: 5 } },
              (variants) => {
                variants.add('id');
                variants.add('product');
                variants.add('title');
                variants.add('price',(price)=>{
                  price.add('amount');
                  price.add('currencyCode');
                });
                variants.add('availableForSale');
                variants.add('image', (image) => {
                  image.add('src');
                  image.add('id');
                  image.add('altText');
                });
              });
          });
      });
    });
  });
  return query;
}

export const recoverPasswordMutation = (email) => {
  const input = client.variable(email, 'String!');
  const mutation = client.mutation('customerRecover', input, (root) => {
      root.add('customerRecover', { args: { email: email } }, (customerRecover) => {
          customerRecover.add('customerUserErrors', (customerUserErrors) => {
              customerUserErrors.add('code');
              customerUserErrors.add('field');
              customerUserErrors.add('message');
          });
      });
  });
  return mutation;
}

export const getProductVariantByIdQuery = (id) => {
  debugger;
  const query = client.query((root) => {
    root.add('node', { args: { id } }, (node) => {
      node.addInlineFragmentOn('ProductVariant', (productVariant) => {
        productVariant.add('id');
        productVariant.add('title');
      });
    });
  });

  return query;
}

export const getProductsByType = (pType, titles = []) => {
  let pQuery = pType ?
    'product_type:' + pType :
    null;
  if (titles.length > 0) {
    pQuery = `${pQuery} AND (`;
    titles.forEach((title, index) => {
      if (index === titles.length - 1) {
        pQuery = `${pQuery} title:${title})`;
      } else {
        pQuery = `${pQuery} title:${title} OR`;
      }
    });
  }
  const query = client.query((root) => {
      root.addConnection('products', {args: {first: 15, query: pQuery}},
        (product) => {
          product.add('title');
          product.add('id');
          product.add('description');
        });
    });
  return query;
};

