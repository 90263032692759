import React, {useState, Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import Select, { components } from 'react-select';

import Breadcrumb from "../common/breadcrumb";
import ProductDescription from "../common/product-description";
import { IconClose2 } from "../common/svg-icons";
import Util from '../../util.js'
import {
    removeFromCart, 
    updateCart, 
    getCheckoutUrl,
    updateOrdernotes,
    updateShowTextBox,
    updateShowReturnAddress,
    updateReturnAddress
} from '../../actions'
import {toast} from 'react-toastify';
import axios from 'axios';
import { CHECKOUT_URLS } from '../../utils/constant';
import {withRouter} from "react-router-dom";
import { getCountries, getCountryByName } from '../../utils/countries';
import { withTranslate } from 'react-redux-multilingual';
import { event } from 'jquery';

const IndicatorSeparator = (
    props
  ) => {
    return (
      <components.IndicatorSeparator {...props}>
        <span>X</span>
      </components.IndicatorSeparator>
    );
};

class cartComponent extends Component {

    constructor (props) {
        super (props);
        this.state = {
            cartItems: props.cartItems,
            cartProducts:{},
            cartQtys:{},
            cartDropdownValues:{},
            cartQtyErrors:{},
            cartValidated: true,
            total: 0,
            totalSaving: 0,
            checkoutProcessing:false,
            overlaminationVariant:false,
            foilStampingVariant:false,
            matteVariant:false,
            tapeVariant:false,
            imprintVariant:false,
            numberingVariant:false,
            oldProducts: [],
            cartAttrs: {},
            isTaxExemptEligible: false,
            taxExempt: false,
            orderNotes: '',
            showTextBox: false,
            updatingTaxExempt: false,
            showNeedTaxExemptionTextLoggedOut: false,
            showNeedTaxExemptionTextLoggedIn: false,
            cadConversion: false,
            countries: [],
            provinces: [],
        }
    }

    componentWillMount() {
        this.setCartItemProperties();
    }

    componentDidMount() {
        let overlaminationVariant = false;
        let foilStampingVariant = false;
        let matteVariant = false;
        let tapeVariant = false;
        let imprintVariant = false;
        let numberingVariant = false;
        const countries = getCountries();
        const country = getCountryByName(this.props.orderOption.alternateReturnAddr.country);
        if (country) {
            this.setState({provinces: country.provinces });
            var {alternateReturnAddr} = this.props.orderOption;
            alternateReturnAddr['state'] = this.props.orderOption.alternateReturnAddr.state || country.provinces[0].name;
            this.props.updateReturnAddress(alternateReturnAddr);
        }
        this.props.products.map(product => {
            if(product.handle === 'overlamination'){
                overlaminationVariant = product.variants[0];
            }
            if(product.handle === 'foil-stamping'){
                foilStampingVariant = product.variants[0];
            }
            if(product.handle === 'matte-varnish'){
                matteVariant = product.variants[0];
            }
            if(product.handle === 'double-sided-tape'){
                tapeVariant = product.variants[0];
            }
            if(product.handle === 'back-imprint'){
                imprintVariant = product.variants[0];
            }
            if(product.handle === 'numbering'){
                numberingVariant = product.variants[0];
            }
        });
        this.setState({ overlaminationVariant, foilStampingVariant, matteVariant, tapeVariant, imprintVariant, numberingVariant, countries, country: "United States" });
        setTimeout(() => window.analytics.page(), 1000);

        this.updateTaxValues();
        this.setLocalStorage();
    }

    componentDidUpdate(_prevProps) {
        if (_prevProps.cartItems !== this.props.cartItems) {
            this.setState({
                cartItems: this.props.cartItems
            });
        }
        // if (_prevProps.orderNotes !== this.props.orderNotes) {
        //     this.setState({
        //         orderNotes: this.props.orderNotes
        //     });
        // }
        if (_prevProps.customer.loggedInUserDetails !== this.props.customer.loggedInUserDetails) {
            this.updateTaxValues();
        }
        this.setLocalStorage();
    } 

    // setting the valus of return address
    onValueChange(e) {
        var {alternateReturnAddr} = this.props.orderOption;
        alternateReturnAddr[e.target.name] = e.target.value;
        this.props.updateReturnAddress(alternateReturnAddr);
      

    }

    onStateChange(e){
        var {alternateReturnAddr} = this.props.orderOption;
        alternateReturnAddr['state'] = e.value;
        this.props.updateReturnAddress(alternateReturnAddr);
     
    }

    // Sets the local storage to match the cart. For Safari browsers.
    setLocalStorage() {
        let cartList = {cart: this.props.cartItems}
        let currentLocalStorage = JSON.parse(localStorage.getItem('state'))

        if(currentLocalStorage.cartList.cart.length !== cartList.cart.length) {
            currentLocalStorage.cartList = cartList
            localStorage.setItem('state', JSON.stringify(currentLocalStorage));
        } 
    }
    textareaChange(e)  {
         //console.log(e.target.value);
         //this.setState({orderNotes:e.target.value})
         this.props.updateOrdernotes(e.target.value);
    }
    setProvince(country) {
       //console.log(country);

    }
    //radio button onchange
    handleOnChange = e => {
        if(e.target.value === '1'){
            this.props.updateShowTextBox(true);
            //this.setState({showTextBox:true})
          }
          else{
            this.props.updateShowTextBox(false);
            //this.setState({showTextBox:false})
          }      
      };
      handleOnChangeAddr = e => {
        if(e.target.value === '1'){
            this.props.updateShowReturnAddress(true);
          }
          else{
            this.props.updateShowReturnAddress(false);
          }      
      };
    updateTaxValues() {
        let taxExempt = false;
        let isTaxExemptEligible = false;
        const isGfsCanada = window.location.href.includes("ca-gfsimpress-readysetprint") || window.location.href.includes("fr-gfsimpress-readysetprint") || window.location.href.includes("ca.gfsimpress.com") || window.location.href.includes("fr.gfsimpress.com");
        if (this.props.customer && !isGfsCanada) {
            let loggedInUserDetails = this.props.customer.loggedInUserDetails;
            if (loggedInUserDetails && loggedInUserDetails.tags) {
                const end_user_of_tag = loggedInUserDetails.tags.find(t => t.value.includes("end_user_of"));
                if (end_user_of_tag) {
                    if (loggedInUserDetails.tags.find(t => t.value.includes("tax_exempt_eligible"))){
                        isTaxExemptEligible = true;
                        if (loggedInUserDetails.tags.find(t => t.value.includes("tax_exempt=true"))){
                            taxExempt = true;
                        }
                        this.setState({
                            showNeedTaxExemptionTextLoggedIn: false
                        });
                    } else {
                        this.setState({
                            showNeedTaxExemptionTextLoggedIn: true
                        });
                    }
                }
                this.setState({
                    showNeedTaxExemptionTextLoggedOut: false
                });
            } else {
                this.setState({
                    showNeedTaxExemptionTextLoggedOut: true
                });
    
            }
        }
        this.setState({
            isTaxExemptEligible, 
            taxExempt
        })
    }

    //Checks if the shopify currency conversion script is loaded, then sets a boolean
    conversionCheck() {
        if (typeof window.Currency !== "undefined" && process.env.REACT_APP_CURRENCY) {
            this.setState({cadConversion: true});
        } else {
            this.setState({cadConversion: false});
        }
    }

    preselectQtyDropdowns(){
        const {cartProducts,cartItems} = this.state;
        let cartQtys = {};
        let cartDropdownValues = {};
        cartItems.forEach(item => {
            const qtyBreaks = cartProducts[item.id].qtyBreaks ? cartProducts[item.id].qtyBreaks.map(brk => parseInt(brk.value)):[];
            cartQtys[item.lineItemId] = item.qty;
            if(qtyBreaks.includes(item.qty)){
                cartDropdownValues[item.lineItemId] = {value: item.qty, label: item.qty };
            }else if(!cartProducts[item.id].lot_pricing){
                cartDropdownValues[item.lineItemId] = {value: 'custom', label: 'Custom Quantity' };
            }
        });
        this.setState({ cartQtys, cartDropdownValues });
    }

    onQtyChange(item, selection){
        this.setState({cartValidated: false});
        let qty = selection.value;
        let { cartQtys, cartDropdownValues, cartItems } = this.state;

        if(isNaN(qty) || qty === ""){
            qty = 1;
        }else{
            qty = parseInt(qty);
        }
        cartQtys = {...cartQtys, [item.lineItemId] : qty };
        cartDropdownValues = {...cartDropdownValues, [item.lineItemId] : selection };
        cartItems = cartItems.map(cartItem => {
            if(cartItem.lineItemId === item.lineItemId){
                cartItem.qty = qty;
            }
            return cartItem;
        });
        this.setStateAndPricing({ cartQtys, cartDropdownValues, cartItems });
    }

    onQtyInputChange(item, value){
        let qty = value;
        if(isNaN(qty) || qty === ""){
            qty = 0;
        }else{
            qty = parseInt(qty);
        }

        let { cartQtys, cartItems } = this.state;
        cartQtys = {...cartQtys, [item.lineItemId] : qty };
        item.qty = qty;
        cartItems[item.lineItemId] = item;
        this.setStateAndPricing({ cartQtys, cartItems });
    }

    validateQty(){
        const {cartProducts,cartItems} = this.state;
        let cartQtyErrors = {};
        let validated = true;
        cartItems.forEach(item => {
            const cartProduct = cartProducts[item.id];
            const qty = item.qty;
            if(!cartProduct.qtyBreaks){
                return;
            }
            const minQty = cartProduct.qtyBreaks[0].value;
            if(qty < minQty){
                validated = false;
                cartQtyErrors[item.lineItemId] = 'Minimum order quantity for this product is '+minQty+'.<br>Add '+(minQty-qty)+' more.';
            }
        });
        this.setState({cartValidated: validated, cartQtyErrors});
    }

    isset(v){
      return typeof(v) !== "undefined";
    }

    getDiscountedPrice(variantQty, priceObject, type, lot_pricing, markup){ // calculates discounted price if applicable else returns 0
      let discountedPrice = 0;
      let lotSize = 0;
      if(priceObject){
        if(!this.isset(type)){
          type = "prices";
        }
        if(!this.isset(priceObject[type])){
            return 0;
        }
        if(this.isset(Object.keys(priceObject[type])[0])){
            // Set the lot size
            if (variantQty >= 500) {
                lotSize = 500; // If quantity is 500 or more, set lot size to 500
            } else {                    
                lotSize = Object.keys(priceObject[type])[0]; // Set lot size to smallest quantity break
            }
        }
        // Loop through the prices of the applicable price grid
        Object.keys(priceObject[type]).forEach(function(qtyBreak) {
          // Find the highest qualifying quantity break
          if(qtyBreak <= variantQty){
            // Set discountedPrice to the net price that matches the current quantity break
            discountedPrice = priceObject[type][qtyBreak];
            if(typeof (priceObject['msrp_markup_multiplier']) !== 'undefined' && typeof (priceObject['msrp_markup_multiplier'][qtyBreak]) !== 'undefined'){
                // Core pricing formula used to calculate the bulk discount
                // Retail = (1 + markup%) * msrp_markup_multiplier * net_row_x
                discountedPrice = (1 + markup/100) * priceObject['msrp_markup_multiplier'][qtyBreak] * discountedPrice;
            }
          }
        });
        if(lot_pricing && lotSize){
          // Adjust discount for lot pricing
          discountedPrice = discountedPrice/lotSize;
        }
      }
      return discountedPrice;
    }

    getCartQty(variantId, type){
        const {cartItems} = this.state;
        type = typeof type !== "undefined" ? type : 'product';
        let lineItems = [];
        if(type === 'product'){
            lineItems = cartItems.filter(item => item.id === variantId);
        }else if(type === 'overlamination'){
            lineItems = cartItems.filter(item => item.id === variantId && item.properties.overlamination);
        }else if(type === 'foil_stamping'){
            lineItems = cartItems.filter(item => item.id === variantId && item.properties.foil_stamping);
        }else if(type === 'matte'){
            lineItems = cartItems.filter(item => item.id === variantId && item.properties.matte);
        }else if(type === 'tape'){
            lineItems = cartItems.filter(item => item.id === variantId && item.properties.tape);
        }else if(type === 'imprint'){
            lineItems = cartItems.filter(item => item.id === variantId && item.properties.imprint);
        }else if(type === 'numbering'){
            lineItems = cartItems.filter(item => item.id === variantId && item.properties.numbering);
        }
        let qty = 0;
        lineItems.map(item => qty += item.qty);
        return qty;
    }

    refreshCartProductQtys(){
        const { cartProducts,cartItems } = this.state;
        cartItems.forEach(item => {
            cartProducts[item.id].qty = this.getCartQty(item.id, 'product');
            cartProducts[item.id].overlaminationQty = this.getCartQty(item.id, 'overlamination');
            cartProducts[item.id].foilStampingQty = this.getCartQty(item.id, 'foil_stamping');
            cartProducts[item.id].matteQty = this.getCartQty(item.id, 'matte');
            cartProducts[item.id].tapeQty = this.getCartQty(item.id, 'tape');
            cartProducts[item.id].imprintQty = this.getCartQty(item.id, 'imprint');
            cartProducts[item.id].numberingQty = this.getCartQty(item.id, 'numbering');
        });
        this.setState({ cartProducts }, () => this.setCartPricing());
    }


    setStateAndPricing(state, fn){
        if(typeof state !== "object"){
            return false;
        }else{
            this.setState(state,function(){
                this.refreshCartProductQtys();
                //Checks when setting pricing if it needs to convert to CAD
                this.conversionCheck();
                if(typeof(fn) === 'function'){
                    fn();
                }
            });
        }
    }

    setCartPricing(){
        let { cartProducts, cartItems } = this.state;
        let total = 0;
        let basePriceTotal = 0;
        Object.keys(cartProducts).forEach( variantId => {
            let cartProduct = cartProducts[variantId];
            const priceObject = cartProduct.priceObject;
            const lot_pricing = cartProduct.lot_pricing;
            const markup = cartProduct.markup;
            let discountedPrice = this.getDiscountedPrice(cartProduct.qty, priceObject, 'prices', lot_pricing, markup);
            let overlaminationPrice = this.getDiscountedPrice(cartProduct.overlaminationQty, priceObject, 'overlamination', lot_pricing, markup);
            let foilPrice = this.getDiscountedPrice(cartProduct.foilStampingQty, priceObject, 'foil', lot_pricing, markup);
            let mattePrice = this.getDiscountedPrice(cartProduct.matteQty, priceObject, 'matte', lot_pricing, markup);
            let tapePrice = this.getDiscountedPrice(cartProduct.tapeQty, priceObject, 'tape', lot_pricing, markup);
            let imprintPrice = this.getDiscountedPrice(cartProduct.imprintQty, priceObject, 'imprint', lot_pricing, markup);
            let numberingPrice = this.getDiscountedPrice(cartProduct.numberingQty, priceObject, 'numbering', lot_pricing, markup);

            if(discountedPrice <= 0){
                discountedPrice = cartProduct.selectedVariant.price;
            }

            cartProduct['overlamination'] = overlaminationPrice;
            cartProduct['foil_stamping'] = foilPrice;
            cartProduct['matte'] = mattePrice;
            cartProduct['tape'] = tapePrice;
            cartProduct['imprint'] = imprintPrice;
            cartProduct['numbering'] = numberingPrice;
            cartProduct['priceCalculated'] = discountedPrice;
            cartProduct['overlaminationBasePrice'] = this.getDiscountedPrice(1, priceObject, 'overlamination', cartProduct.lot_pricing);
            cartProduct['foilStampingBasePrice'] = this.getDiscountedPrice(1, priceObject, 'foil', cartProduct.lot_pricing);
            cartProduct['matteBasePrice'] = this.getDiscountedPrice(1, priceObject, 'matte', cartProduct.lot_pricing);
            cartProduct['tapeBasePrice'] = this.getDiscountedPrice(1, priceObject, 'tape', cartProduct.lot_pricing);
            cartProduct['imprintBasePrice'] = this.getDiscountedPrice(1, priceObject, 'imprint', cartProduct.lot_pricing);
            cartProduct['numberingBasePrice'] = this.getDiscountedPrice(1, priceObject, 'numbering', cartProduct.lot_pricing);
            cartProducts[variantId] = cartProduct;
        });
        //console.log(cartProducts);
        cartItems = cartItems.map(item => {
            let cartProduct = cartProducts[item.id];
            item.productPrice = cartProduct.priceCalculated;
            item.overlaminationPrice = 0;
            item.foilStampingPrice = 0;
            item.mattePrice = 0;
            item.tapePrice = 0;
            item.imprintPrice = 0;
            item.numberingPrice = 0;
            let price = item.price;
            if (!item.price) {
                price = parseFloat(item.productPrice.toFixed(2));//item.productData.variants.edges.find(v => v.node.id === item.selectedVariant.id).node.price;
            }
            let productPrice = item.productPrice ? item.productPrice : 0;
            if(item.properties.overlamination){
                item.overlaminationPrice = cartProduct.overlamination;
            }
            if(item.properties.foil_stamping){
                item.foilStampingPrice = cartProduct.foil_stamping;
            }
            if(item.properties.matte){
                item.mattePrice = cartProduct.matte;
            }
            if(item.properties.tape){
                item.tapePrice = cartProduct.tape;
            }
            if(item.properties.imprint){
                item.imprintPrice = cartProduct.imprint;
            }
            if(item.properties.numbering){
                item.numberingPrice = cartProduct.numbering;
            }
            price = parseFloat(productPrice.toFixed(2))+item.overlaminationPrice+item.foilStampingPrice+item.mattePrice+item.tapePrice+item.imprintPrice+item.numberingPrice;
            item.properties['Item Price'] = productPrice;
            total += (price * item.qty);
            basePriceTotal += (item.selectedVariant.price * item.qty);
            if(item.properties.overlamination){
                basePriceTotal += (item.qty * cartProduct.overlaminationBasePrice);
            }
            if(item.properties.foil_stamping){
                basePriceTotal += (item.qty * cartProduct.foilStampingBasePrice);
            }
            if(item.properties.matte){
                basePriceTotal += (item.qty * cartProduct.matteBasePrice);
            }
            if(item.properties.tape){
                basePriceTotal += (item.qty * cartProduct.tapeBasePrice);
            }
            if(item.properties.imprint){
                basePriceTotal += (item.qty * cartProduct.imprintBasePrice);
            }
            if(item.properties.numbering){
                basePriceTotal += (item.qty * cartProduct.numberingBasePrice);
            }
            return item;
        });
        const totalSaving = basePriceTotal - total;
        this.setState({ cartProducts, cartItems, total, totalSaving }, () => this.updateStoreCart());
        this.validateQty();
    }

    setCartItemProperties(){
        // FOLLOWING IS TO BE ADDED AS IS TO ALL ITEMS
        const commonValues = {
            qty:0,
            priceCalculated: false,
            qtyBreaks: [],
        }

        const { cartItems } = this.state;
        let cartProducts = {};

        cartItems.forEach(item => {
            this.setProductProperties(item);
            if(!this.isset(cartProducts[item.id])){
                cartProducts[item.id] = { 
                    ...commonValues,
                    barcode: item.barcode,
                    priceObject: item.priceObject,
                    lot_pricing:item.lot_pricing,
                    markup:item.markup,
                    selectedVariant: item.selectedVariant,
                    productData: item.productData,
                };
            }
        });
        this.setState({cartProducts},() => {
            Object.keys(cartProducts).forEach( variantId => {
                if(cartProducts[variantId].priceObject){
                    cartProducts[variantId].qty = this.getCartQty(variantId, 'product');
                    cartProducts[variantId].overlaminationQty = this.getCartQty(variantId, 'overlamination');
                    cartProducts[variantId].foilStampingQty = this.getCartQty(variantId, 'foil_stamping');
                    cartProducts[variantId].matteQty = this.getCartQty(variantId, 'matte');
                    cartProducts[variantId].tapeQty = this.getCartQty(variantId, 'tape');
                    cartProducts[variantId].imprintQty = this.getCartQty(variantId, 'imprint');
                    cartProducts[variantId].numberingQty = this.getCartQty(variantId, 'numbering');
                    let qtyOptions = Object.keys(cartProducts[variantId].priceObject.prices).map(brk => ({value: brk, label: brk }));
                    if(!cartProducts[variantId].lot_pricing){
                        qtyOptions.push({value:'custom',label:'Custom Quantity'});
                    }
                    cartProducts[variantId].qtyBreaks = qtyOptions;
                }else{
                    cartProducts[variantId].qtyBreaks = false;
                }
            });
            this.setStateAndPricing({ cartProducts },() => this.preselectQtyDropdowns());
        });
    }

    setProductProperties(item) {
        let fullErrorMessage = "";
        let { productData } = item;
        if (!this.isset(productData.variants)) {
            return;
        }

        let price_file_url = false;
        let priceGridTag = productData.tags.find(tag => tag.indexOf('$=') > -1);
        if (priceGridTag && priceGridTag != "") {
            priceGridTag = priceGridTag.replace('$=', '');
            const currentEnvironment = process.env.REACT_APP_URL_PRICING_JSON ? process.env.REACT_APP_URL_PRICING_JSON : "https://designer.readysetprint.com/apps/shopify-json/pricing";
            price_file_url = currentEnvironment + "/" + priceGridTag + '.json';
        } else {
            fullErrorMessage = `The product ${productData.title} doesn't contain the tag $=\nPlease add tag in order to retrieve JSON pricing file.`;
            if (process.env.REACT_APP_URL_PRICING_JSON) {
                toast.error(fullErrorMessage, { className: "pricing-error" });
            } else {
                console.log(fullErrorMessage);
            }
        }

        let markup = 0;
        let markupTag = productData.tags.find(tag => tag.indexOf('markup=') > -1);
        if (markupTag && markupTag != "") {
            markup = markupTag.replace('markup=', '');
            markup = Number(markup);
            if (isNaN(markup)) {
                markup = 0;
            }
        }
        if (price_file_url) {
            axios.get(price_file_url)
                .then(res => {
                    const { isJsonValid, errorMessage } = Util.validatePricingJson(res.data, productData.variants.edges);
                    if (!isJsonValid) {
                        fullErrorMessage = `Pricing JSON contains errors: \n${errorMessage}`;
                        if (process.env.REACT_APP_URL_PRICING_JSON) {
                            toast.error(fullErrorMessage, { className: "pricing-error" });
                        } else {
                            console.log(fullErrorMessage);
                        }
                    } else {
                        this.setState({ priceJson: res.data }, () => {
                            this.onPriceJsonFetch(item);
                        });
                    }
                })
                .catch(() => {
                    fullErrorMessage = `Couldn't retrieve pricing JSON.\nPlease check that JSON pricing grid exists with name ${priceGridTag}.json`;
                    if (process.env.REACT_APP_URL_PRICING_JSON) {
                        toast.error(fullErrorMessage, { className: "pricing-error" });
                    } else {
                        console.log(fullErrorMessage);
                    }
                });
        } else {
            console.error('pricing tag unavailable');
        }
    }

    onPriceJsonFetch(item) {
        let { priceJson } = this.state;
        const priceObject = Util.getPricingObject(item.barcode, priceJson);
        item.priceObject = priceObject;
    }

    removeItemFromCart(item){
        this.props.removeFromCart(item).then(()=>{
            const {cartItems} = this.props;
            this.setStateAndPricing({cartItems});
        });
    }

    updateStoreCart(){
        this.props.updateCart(this.state.cartItems).then(()=>{
            // console.log('store cart updated');
            // console.log(this.state.cartItems);
        });
    }

    cartAttrUpdate(key, value){
        let cartAttrs = this.state.cartAttrs;
        cartAttrs[key] = value;
        this.setState({cartAttrs});
    }

    checkout(){
        this.setState({checkoutProcessing:true});

        let checkoutFields = {};        
        let returnAddressFields = {};

        const reseller = Util.getReseller();

        let customAttributes = [
             {key: 'reseller_id', value: `${reseller || 'staging'}`},
             {key: 'reseller_logo', value: document.getElementsByClassName('brand-logo')[0].getElementsByTagName('img')[0].src}
        ]

        if (window.stripe_user_id) {
            customAttributes.push({
                key: "stripe_user_id", value: window.stripe_user_id
            });
        }

        if (window.StouseAccountNumber) {
            customAttributes.push({
                key: "StouseAccountNumber", value: window.StouseAccountNumber.toString()
            })
        }
        if (window.customerServiceEmail) {
            customAttributes.push({
                key: "customer_service_email", value: window.customerServiceEmail
            });
        }
        if (window.customSubdomain) {
            customAttributes.push({
                key: "custom_subdomain", value: window.customSubdomain
            });
        }
        if (window.gaId) {
            customAttributes.push({
                key: "ga_id", value: window.gaId
            });
        }

        let overlaminationItems = [];
        let foilStampingItems = [];
        let matteItems = [];
        let tapeItems = [];
        let imprintItems = [];
        let numberingItems = [];

        let lineItems = this.state.cartItems.map(item => {
            if(item.properties.overlamination){
                overlaminationItems.push({
                    variantId: this.state.overlaminationVariant.id,
                    quantity: parseInt(item.qty),
                    customAttributes: {
                        key: "Item Price",
                        value: String(item.overlaminationPrice)
                    }
                });
            }
            if(item.properties.foil_stamping){
                foilStampingItems.push({
                    variantId: this.state.foilStampingVariant.id,
                    quantity: parseInt(item.qty),
                    customAttributes: {
                        key: "Item Price",
                        value: String(item.foilStampingPrice)
                    }
                });
            }
            if(item.properties.matte){
                matteItems.push({
                    variantId: this.state.matteVariant.id,
                    quantity: parseInt(item.qty),
                    customAttributes: {
                        key: "Item Price",
                        value: String(item.mattePrice)
                    }
                });
            }
            if(item.properties.tape){
                tapeItems.push({
                    variantId: this.state.tapeVariant.id,
                    quantity: parseInt(item.qty),
                    customAttributes: {
                        key: "Item Price",
                        value: String(item.tapePrice)
                    }
                });
            }
            if(item.properties.imprint){
                imprintItems.push({
                    variantId: this.state.imprintVariant.id,
                    quantity: parseInt(item.qty),
                    customAttributes: {
                        key: "Item Price",
                        value: String(item.imprintPrice)
                    }
                });
            }
            if(item.properties.numbering){
                numberingItems.push({
                    variantId: this.state.numberingVariant.id,
                    quantity: parseInt(item.qty),
                    customAttributes: {
                        key: "Item Price",
                        value: String(item.numberingPrice)
                    }
                });
            }
            let customAttributes = Object.keys(item.properties).map(key => {
                if(key === 'Size' || key === 'Material' || key === 'Sides'){
                    return { key, value: String(item.properties[key])};
                }
                return { key: `_${key}`, value: String(item.properties[key])};
            });
            if(item.white_halo){
                customAttributes.push({
                    key: 'white_halo',
                    value: String(item.white_halo)
                });
            }
            if( this.isset(item.properties.Size) && item.properties.Size === 'custom'
                && this.isset(item.properties.customWidth) && this.isset(item.properties.customHeight) 
                && item.properties.customWidth !== "" && item.properties.customHeight !== ""
                ){
                customAttributes.push({  key: 'Custom Size', value: item.properties.customWidth+'" x '+item.properties.customHeight+'"' })
            } 
            return {
                variantId: item.id,
                quantity: parseInt(item.qty),
                customAttributes
            }
        });
        lineItems = [...lineItems, ...overlaminationItems, ...foilStampingItems, ...matteItems, ...tapeItems, ...imprintItems, ...numberingItems];

        const cartAttrs = this.state.cartAttrs;
        for(let key in cartAttrs){
            customAttributes.push({ key, value: cartAttrs[key] });
        }
        
        checkoutFields = {
            lineItems,
            customAttributes
        }
        if(this.props.orderOption.showTextBox){
            let note = this.props.orderOption.orderNotes;
            customAttributes.push({ key: 'Order_Notes', value: note });
        }
        if(this.props.orderOption.showAlternateReturnAddr){
            const returnaddr = this.props.orderOption.alternateReturnAddr;
            if(typeof returnaddr === "object" && returnaddr !== null){
                var formattedAdd = `company:${returnaddr.companyName}, 
                address1:${returnaddr.addressLine1},
                address2:${returnaddr.addressLine2},
                city:${returnaddr.city}, 
                state:${returnaddr.state}, 
                zipcode:${returnaddr.zipCode},
                country:${returnaddr.country}`;
                customAttributes.push({ key: 'Return_Address', value: formattedAdd });

            }
              
        }
        let customerToken = false;
        if(this.props.customer && this.props.customer.loggedIn && Object.keys(this.props.customer.loggedInUserDetails).length > 0 && this.props.customer.customerAccessToken && this.props.customer.customerAccessToken.accessToken !== null){
            customerToken = this.props.customer.customerAccessToken.accessToken;
            checkoutFields.email = this.props.customer.loggedInUserDetails.email;
            const addressGraph = this.props.customer.loggedInUserDetails.defaultAddress;
            if(typeof addressGraph === "object" && addressGraph !== null){
                checkoutFields.shippingAddress = {
                    firstName: addressGraph.firstName,
                    lastName: addressGraph.lastName && addressGraph.lastName !== "" ? addressGraph.lastName : this.props.customer.loggedInUserDetails.lastName,
                    company: addressGraph.company,
                    address1: addressGraph.address1,
                    address2: addressGraph.address2,
                    city: addressGraph.city,
                    province: addressGraph.province,
                    country: addressGraph.country,
                    zip: addressGraph.zip,
                    phone:addressGraph.phone,
                };
            }
        }
        this.props.getCheckoutUrl(checkoutFields, customerToken, cart => {
            if(cart){
                if (cart.errors) {
                    const oldProductsError = cart.errors.filter(e => e.code === "INVALID");
                    if (oldProductsError.length > 0) {
                        let oldProducts = [];
                        oldProductsError.forEach(iE => {
                            oldProducts.push(iE.variantId)
                        });
                        this.setState({
                            oldProducts
                        })
                        toast.error("Some products in your cart are no longer available, please remove them from your cart before proceeding to checkout.");
                    } else {
                        toast.error("There was an error processing checkout. Please try again.");
                    }
                    this.setState({checkoutProcessing:false});
                } else {
                    document.cookie = `_checkoutstarted=true; Path=/;`;
                    localStorage.setItem('_checkoutstarted',true);
                    this.setState({
                        oldProducts: []
                    })
                    var webUrl = "";
                    const customCheckoutUrlExists = window.customSubdomain ? CHECKOUT_URLS.find(c => c.customSubdomain === window.customSubdomain) : null; 
                    if (customCheckoutUrlExists) {
                        webUrl = cart.checkoutUrl.replace('white-label-printing.myshopify.com', customCheckoutUrlExists.checkoutUrl);
                        webUrl = webUrl.replace('white-label-staging.myshopify.com', customCheckoutUrlExists.checkoutUrl);
                    } else {
                        webUrl = cart.checkoutUrl.replace('white-label-printing.myshopify.com','checkout.readysetprint.com');
                    }

                    const locale = this.props.locale;
                    if (locale) {
                        webUrl += webUrl.includes("?") ? `&` : `?`;
                        webUrl += `locale=${locale}`;
                    }

                    window.location.assign(webUrl);
                }
            }
        });
    }

    updateCustomerTaxExempt = (ev) => {
        const taxExempt = ev.target.checked;
        this.setState({
            taxExempt,
            checkoutProcessing: true,
            updatingTaxExempt: true
        });
        const { customer } = this.props;
        axios.post(
            process.env.REACT_APP_TAGGING_API_URL + "/update-customer-tax-exempt",
            {
                customerId: customer.loggedInUserDetails.id,
                taxExempt
            },
            { 'Content-Type': 'application/json' }
        )
            .then(response => {
                if (response.data && response.data.customer) {
                    console.log("Customer tax exempt updated correctly");
                } else {
                    console.error(`Error while updating customer tax exempt: ${JSON.stringify(response)}`);
                    this.setState({
                        taxExempt: !taxExempt 
                    })
                }
                this.setState({
                    checkoutProcessing: false,
                    updatingTaxExempt: false
                });
            })
            .catch(err => {
                console.error(`Error while updating customer tax exempt: ${JSON.stringify(err)}`);
                this.setState({
                    checkoutProcessing: false,
                    updatingTaxExempt: false,
                    taxExempt: !taxExempt
                });
            });

    }

    countryOnChange = (country) => {

        if (country) {
            const countryFound = getCountryByName(country);
            if (countryFound) {
                this.setState({provinces: countryFound.provinces,province: countryFound.provinces[0].name});
                var {alternateReturnAddr} = this.props.orderOption;
                alternateReturnAddr['country'] = country;
                alternateReturnAddr['state'] = countryFound.provinces[0].name;
                this.props.updateReturnAddress(alternateReturnAddr);
            }
        }

    }

    

    render (){
        const {cartItems, oldProducts, countries,provinces} = this.state;
        const { translate } = this.props;

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>{translate("Cart")} | {translate("Cart List")}</title>
                    <meta name="description" content="" />
                    {/* <script async="true" src="https://cdn.shopify.com/s/javascripts/currencies.js"></script> */}
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={translate('Your Cart')}/>


                {cartItems.length>0 ?
                <section className="cart-page pt-lg-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="mb-3 mb-md-5 "> {translate("Your Cart")} </h1>
                                <div className="row">   
                                    <div className="col-lg-8 pr-lg-5 mb-3 mb-md-5">
                                        <div className="item-row item-row-header pt-0 pb-1">
                                            <div className="row">
                                                <div className="col-md-6 item-description">                                                    
                                                    <span className="text-capitalize">{translate("description")}</span>
                                                </div>
                                                <div className="col-md-5 pr-md-5">
                                                    <div className="row align-items-center order-info">
                                                        <div className="col-8 item-quantity">
                                                            <span>{translate("Quantity")}</span>
                                                        </div>
                                                        <div className="col-4 item-total mb-3">
                                                            <span>{translate("Total")}</span>
                                                        </div>
                                                    </div>                                     
                                                </div>
                                            </div>
                                        </div>
                                    {
                                        cartItems.map((item, index) => {
                                        const isOldProduct = oldProducts.includes(item.id);
                                        let specs = Util.getSpecsObj(item.productData.specs);
                                        let price = item.price;
                                        if (!item.price) {
                                            if (item.productPrice) { 
                                                price = parseFloat(item.productPrice.toFixed(2));
                                            } else {
                                                price = item.productData.variants.edges.find(v => v.node.id === item.selectedVariant.id).node.price;
                                            }
                                        }
                                        return (
                                        <div className={`item-row ${isOldProduct ? "red-border" : ""}`} key={index}>
                                            <div className="row">
                                                <div className="col-md-6 item-description">
                                                    <ProductDescription product={item} specs={specs} />
                                                </div>
                                                <div className="col-md-5 pr-md-5">
                                                    <label style={{display: 'none'}}> {translate("Quantity")} </label>
                                                    <div className="row align-items-center order-info">
                                                        <div className="col-8 ">
                                                            <div className="item-quantity">
                                                                {/* item.productPrice } + { item.overlaminationPrice } + { item.foilStampingPrice }  + { item.mattePrice }  + { item.tapePrice }  + { item.imprintPrice }  + { item.numberingPrice */} 
                                                                <SelectQuantity 
                                                                    value={this.state.cartDropdownValues[item.lineItemId]}
                                                                    inputValue={this.state.cartQtys[item.lineItemId]}
                                                                    onChange={ (selection) => this.onQtyChange(item, selection) }
                                                                    onInputChange={ (e) => this.onQtyInputChange(item, e.target.value) }
                                                                    options={this.state.cartProducts[item.id].qtyBreaks}
                                                                    showCustomQtyField={typeof(this.state.cartDropdownValues[item.lineItemId]) !== "undefined"?this.state.cartDropdownValues[item.lineItemId].value === 'custom':false}
                                                                    qtyerror={this.state.cartQtyErrors[item.lineItemId]}
                                                                    />
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-4 item-total">
                                                            {/* If cadConversion = true then convert the total using the Shopify currency convert script to CAD and apply a 2.3% conversion fee, otherwise render in USD */}
                                                            <span>
                                                                {this.state.cadConversion ? '$' + (
                                                                window.Currency.convert(Math.round(parseFloat(price*item.qty)*100)/100, 'USD', 'CAD')*1.023).toFixed(2)   
                                                                :
                                                                '$' + (Math.round(parseFloat(price*item.qty)*100)/100).toFixed(2)}
                                                            </span>
                                                        </div>
                                                    </div>                                     
                                                </div>
                                                <div className="close-icon">
                                                    <button className="btn btn-link" onClick={ () => this.removeItemFromCart(item) }><IconClose2 /></button>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })
                                    }
                                    <div className="tax-exempt-container">
                                        {this.state.isTaxExemptEligible &&
                                            <>
                                                <div>Tax exempt <input type="checkbox" checked={this.state.taxExempt} onChange={this.updateCustomerTaxExempt} /></div>
                                                <p>{this.state.updatingTaxExempt ? "Updating tax exempt status...": ""}</p>
                                            </>
                                        }
                                        {
                                            this.state.showNeedTaxExemptionTextLoggedIn && 
                                            <>
                                                <div className="title mt-2">{translate("Need tax exemption?")}</div>
                                                <p><a href="/account">{translate("Request tax exemption")}</a></p>
                                            </>
                                        }
                                        {
                                            this.state.showNeedTaxExemptionTextLoggedOut && 
                                            <>
                                                <div className="title mt-2">{translate("Need tax exemption?")}</div>
                                                <p>
                                                    <Link className="text-capitalize" to={
                                                        {"pathname": "/login", "state": { "from": this.props.history.location.pathname }}
                                                        } >{translate("login")}
                                                    </Link> {translate("or")} <Link className="text-capitalize" to={
                                                        {"pathname": "/register", "state": { "from": this.props.history.location.pathname }}
                                                        }>
                                                            {translate("Create an Account")}
                                                    </Link>
                                                </p>
                                            </>
                                        }
                                    </div>
                                    <div className="order_notes">
            <div style={{ display: 'flex' }}>
              <div style={{ paddingRight: '10px', paddingBottom : '10px'}}>Special instructions for your order: </div>
              <div style={{ display: 'flex' }}>                
                <div style={{ paddingRight: '15px' }}><input id="radio-no" type="radio" value="2" checked={this.props.orderOption.showTextBox === false} onChange={this.handleOnChange} name="instructions"/><label htmlFor="radio-no">No</label></div>
                <div style={{ paddingRight: '15px' }}><input id="radio-yes" type="radio" value="1" checked={this.props.orderOption.showTextBox === true} onChange={this.handleOnChange} name="instructions"/><label htmlFor="radio-yes">Yes</label></div>
              </div>
            </div>
            {this.props.orderOption.showTextBox &&
            <div style={{ margin:'0 auto'}}>                
              <textarea className = "ordernotes" onChange={this.textareaChange.bind(this)} rows = "5" cols = "100" style={{width:'100%', height:'auto'}} name="note" placeholder="Have specific order notes? Please provide additional direction or requests here.">{this.props.orderOption.orderNotes}</textarea>
            </div>
    }
</div>
{/* add alternate address*/}
<div style={{ display: 'flex' }}>
              <div style={{ paddingRight: '10px', paddingBottom : '10px'}}>Use alternate return address: </div>
              <div style={{ display: 'flex' }}>                
                <div style={{ paddingRight: '15px' }}><input id="radio-no" type="radio" value="2" checked={this.props.orderOption.showAlternateReturnAddr === false} onChange={this.handleOnChangeAddr} name="address"/><label htmlFor="radio-no">No</label></div>
                <div style={{ paddingRight: '15px' }}><input id="radio-yes" type="radio" value="1" checked={this.props.orderOption.showAlternateReturnAddr === true} onChange={this.handleOnChangeAddr} name="address"/><label htmlFor="radio-yes">Yes</label></div>
              </div>
            </div>
                           {this.props.orderOption.showAlternateReturnAddr && this.props.orderOption.alternateReturnAddr &&
                           <div>
                                  <div className="form-group d-flex">
                                            <input type="text" name="companyName" value={this.props.orderOption.alternateReturnAddr.companyName} onChange={(e)=>this.onValueChange(e)} className="form-control"                                        
                                                   placeholder={translate("Company name")}/>                                              
                                </div>
                                  <div className="form-group d-flex">
                                            <input type="text" name="addressLine1" value={this.props.orderOption.alternateReturnAddr.addressLine1} onChange={(e)=>this.onValueChange(e)} className="form-control"                                        
                                                   placeholder={translate("Address Line 1")}/>                                              
                                </div>
                                <div className="form-group d-flex">
                                            <input type="text" name="addressLine2" value={this.props.orderOption.alternateReturnAddr.addressLine2} onChange={(e)=>this.onValueChange(e)} className="form-control"                                        
                                                   placeholder={translate("Address Line 2")}/>                                              
                                </div>
                                <div className="form-group d-flex">
                                            <input type="text"
                                                   className="form-control"
                                                   value={this.props.orderOption.alternateReturnAddr.city}
                                                   name="city" onChange={(e)=>this.onValueChange(e)}
                                                   placeholder={translate("City")}/>                                               
                                </div>
                                <div className="form-group d-flex">
                                <div style={{width: "100%"}}>
                                {/* <Select
                                                value={{ label: this.props.orderOption.alternateReturnAddr.state, value: this.props.orderOption.alternateReturnAddr.state }}
                                                name="state" onChange={(e)=>this.onStateChange(e)}
                                                options={usStates.map(state => ({ label: state.name, value: state.name }))}
                                            /> */}
                                            <Select
                                                value={{ label: this.props.orderOption.alternateReturnAddr.state, value: this.props.orderOption.alternateReturnAddr.state }}
                                                onChange={(e)=>this.onStateChange(e)}
                                                options={provinces.map(state => ({ label: state.name, value: state.name }))}
                                            />
                                            </div>
                                 </div>
                                <div className="form-group d-flex" >
                                    <div style={{width: "100%"}}>
                                            {/* <input type="text"
                                                   className="form-control" 
                                                   value={this.props.orderOption.alternateReturnAddr.country}                                                  
                                                   name="country"                                                                                      
                                                   placeholder={translate("Country")} readOnly/>   
                                                   
                                                   */}
                                            <Select 
                                                    name="country"
                                                    placeholder={translate("Country")}  
                                                    value={{label: this.props.orderOption.alternateReturnAddr.country, value: this.props.orderOption.alternateReturnAddr.country}}
                                                    onChange={selection => this.countryOnChange(selection.value)}
                                                    options={countries.map(c => ({label: c.country, value: c.country}))}
                                                    isDisabled="true"
                                                />
                                    </div>
                                </div>
                                        <div className="form-group d-flex">
                                            <input type="text"
                                                   className="form-control" 
                                                   value={this.props.orderOption.alternateReturnAddr.zipCode}
                                                   name="zipCode" onChange={(e)=>this.onValueChange(e)}                                                
                                                   placeholder={translate("Zip Code")}/>                                                   
                                        </div>
                                </div>
                                     }
                                    </div>
                                    <div className="col-lg-4 mb-5" >
                                        <div className="subtotal-box text-center">
                                            {/* If cadConversion = true then convert the total using the Shopify currency convert script to CAD and apply a 2.3% conversion fee, otherwise render in USD */}
                                            <h3>{translate("Subtotal")}: {translate("$##", {amount: (this.state.cadConversion ? (window.Currency.convert((this.state.total*100/100), 'USD', 'CAD')*1.023).toFixed(2) + '   (CAD)' : (Math.round(parseFloat(this.state.total)*100)/100).toFixed(2))})}</h3>
                                            { window.location.href.indexOf('gfsimpress') > -1 || window.location.href.indexOf('deploy-preview') > -1 ? (
                                                <div className="mt-4">
                                                    <input type="text" value={ this.state.cartAttrs.gfscn != null?this.state.cartAttrs.gfscn:'' } onChange={(e) => this.cartAttrUpdate(e.target.name,e.target.value)} className="form-control form-field p-3" style={{borderRadius: 5}} id="gfscn" name="gfscn" placeholder={translate("gfs_customer_number")} />
                                                </div>
                                                ):''}
                                            <hr/>
                                            <button className={`btn d-block cart-checkout-btn ${!this.state.cartValidated || this.state.checkoutProcessing?"btn-secondary":"btn-primary"}`} 
                                                disabled={!this.state.cartValidated || this.state.checkoutProcessing} onClick={this.checkout.bind(this)}>{translate("checkout")}</button>
                                            <Link className="text-primary d-block text-center cart-shopping-link" to="/">{translate("Continue Shopping")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </section>
                :
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div >
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                                        <h3>
                                            <strong>{translate("Your Cart is Empty")}</strong>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }
            </div>
        )
    }
}

const SelectQuantity = (props) => (
    <div>
        <Select
            styles={{
                control: (base, _state) => ({
                ...base,
                colors: {
                    text: ' black',
                    primary25: 'black',
                    primary: 'black',
                },   
                maxHeight: '50px',
                minHeight: '32px',
                color: 'black',
                fontFamily: 'Roboto',
                }),
                singleValue: base => ({
                    ...base,
                    color: "black"
                }),
                input: base => ({
                    ...base,
                    color: "black"
                }), 
                indicatorSeparator: () => {}, // removes the "stick"
                dropdownIndicator: defaultStyles => ({
                ...defaultStyles,
                color: 'black' // your changes to the arrow
                })
            }}
            value={props.value}
            options={props.options}
            onChange={props.onChange}
            components={{ IndicatorSeparator }}
        />

        <div>
            <input style={{ borderRadius: "5px", marginBottom: 6 }} className="mt-2 form-control" type={props.showCustomQtyField?"text":"hidden"} onChange={props.onInputChange} value={
                (typeof(props.inputValue) !== "undefined" && !isNaN(props.inputValue))?parseInt(props.inputValue):(typeof(props.value)!== "undefined" && !isNaN(props.inputValue))?parseInt(props.value.value):0
            }  />
            <div className="error" style={{color: '#f00'}} dangerouslySetInnerHTML={{__html: props.qtyerror}}></div>
        </div>
    </div>
);


const mapStateToProps = (state) => {
    const { Intl } = state;
    const { locale } = Intl;
    return {
        cartItems: state.cartList.cart,
        products: state.data.products,
        customer: state.customer,
        orderOption: state.orderOption,
        locale
    }
}

export default withRouter(connect(
    mapStateToProps,
    {
        removeFromCart, 
        updateCart, 
        getCheckoutUrl,
        updateOrdernotes,
        updateShowTextBox,
        updateShowReturnAddress,
        updateReturnAddress
    }
)(withTranslate(cartComponent)))
