const Checkout = {
  "name": "Checkout",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliedGiftCards": "AppliedGiftCard",
    "availableShippingRates": "AvailableShippingRates",
    "completedAt": "DateTime",
    "createdAt": "DateTime",
    "currencyCode": "CurrencyCode",
    "customAttributes": "Attribute",
    "customer": "Customer",
    "email": "String",
    "id": "ID",
    "lineItems": "CheckoutLineItemConnection",
    "note": "String",
    "order": "Order",
    "orderStatusUrl": "URL",
    "paymentDue": "MoneyV2",
    "ready": "Boolean",
    "requiresShipping": "Boolean",
    "shippingAddress": "MailingAddress",
    "shippingLine": "ShippingRate",
    "subtotalPrice": "MoneyV2",
    "taxExempt": "Boolean",
    "taxesIncluded": "Boolean",
    "totalPrice": "MoneyV2",
    "totalTax": "MoneyV2",
    "updatedAt": "DateTime",
    "vaultUrl": "URL",
    "webUrl": "URL"
  },
  "implementsNode": true
};
Object.freeze(Checkout.fieldBaseTypes);
var Checkout$1 = Object.freeze(Checkout);

const ID = {
  "name": "ID",
  "kind": "SCALAR"
};
var ID$1 = Object.freeze(ID);

const Boolean = {
  "name": "Boolean",
  "kind": "SCALAR"
};
var Boolean$1 = Object.freeze(Boolean);

const CheckoutLineItemConnection = {
  "name": "CheckoutLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CheckoutLineItemEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(CheckoutLineItemConnection.fieldBaseTypes);
var CheckoutLineItemConnection$1 = Object.freeze(CheckoutLineItemConnection);

const PageInfo = {
  "name": "PageInfo",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "hasNextPage": "Boolean",
    "hasPreviousPage": "Boolean"
  },
  "implementsNode": false
};
Object.freeze(PageInfo.fieldBaseTypes);
var PageInfo$1 = Object.freeze(PageInfo);

const CheckoutLineItemEdge = {
  "name": "CheckoutLineItemEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "CheckoutLineItem"
  },
  "implementsNode": false
};
Object.freeze(CheckoutLineItemEdge.fieldBaseTypes);
var CheckoutLineItemEdge$1 = Object.freeze(CheckoutLineItemEdge);

const String = {
  "name": "String",
  "kind": "SCALAR"
};
var String$1 = Object.freeze(String);

const CheckoutLineItem = {
  "name": "CheckoutLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customAttributes": "Attribute",
    "id": "ID",
    "quantity": "Int",
    "title": "String",
    "variant": "ProductVariant"
  },
  "implementsNode": true
};
Object.freeze(CheckoutLineItem.fieldBaseTypes);
var CheckoutLineItem$1 = Object.freeze(CheckoutLineItem);

const ProductVariant = {
  "name": "ProductVariant",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availableForSale": "Boolean",
    "id": "ID",
    "image": "Image",
    "price": "MinVariantPrice",
    "product": "Product",
    "selectedOptions": "SelectedOption",
    "title": "String",
    "weight": "Float",
    "weightUnit": "WeightUnit",
    "sku": "String"
  },
  "implementsNode": true
};
Object.freeze(ProductVariant.fieldBaseTypes);
var ProductVariant$1 = Object.freeze(ProductVariant);

const Float = {
  "name": "Float",
  "kind": "SCALAR"
};
var Float$1 = Object.freeze(Float);

const WeightUnit = {
  "name": "WeightUnit",
  "kind": "ENUM"
};
var WeightUnit$1 = Object.freeze(WeightUnit);


const MoneyV2 = {
  "name": "MoneyV2",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Decimal",
    "currencyCode": "CurrencyCode",
  }
};
var MoneyV2$1 = Object.freeze(MoneyV2);

const Decimal = {
  "name": "Decimal",
  "kind": "SCALAR",
};
var Decimal$1 = Object.freeze(Decimal);

const Image = {
  "name": "Image",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "altText": "String",
    "id": "ID",
    "src": "URL"
  },
  "implementsNode": false
};
Object.freeze(Image.fieldBaseTypes);
var Image$1 = Object.freeze(Image);

const URL = {
  "name": "URL",
  "kind": "SCALAR"
};
var URL$1 = Object.freeze(URL);

const Int = {
  "name": "Int",
  "kind": "SCALAR"
};
var Int$1 = Object.freeze(Int);

const CropRegion = {
  "name": "CropRegion",
  "kind": "ENUM"
};
var CropRegion$1 = Object.freeze(CropRegion);

const SelectedOption = {
  "name": "SelectedOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "name": "String",
    "value": "String"
  },
  "implementsNode": false
};
Object.freeze(SelectedOption.fieldBaseTypes);
var SelectedOption$1 = Object.freeze(SelectedOption);

  const Product = {
    "name": "Product",
    "kind": "OBJECT",
    "fieldBaseTypes": {
      "collections": "CollectionConnection",
      "createdAt": "DateTime",
      "description": "String",
      "descriptionHtml": "HTML",
      "handle": "String",
      "id": "ID",
      "node": "Node",
      "images": "ImageConnection",
      "metafields": "MetafieldConnection",
      "options": "ProductOption",
      "status": "String",
      "productType": "String",
      "publishedAt": "DateTime",
      "tags": "String",
      "title": "String",
      "updatedAt": "DateTime",
      "variantBySelectedOptions": "ProductVariant",
      "variants": "ProductVariantConnection",
      "vendor": "String"
    },
    "implementsNode": true
  };
  Object.freeze(Product.fieldBaseTypes);
  var Product$1 = Object.freeze(Product);

const CollectionConnection = {
  "name": "CollectionConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CollectionEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(CollectionConnection.fieldBaseTypes);
var CollectionConnection$1 = Object.freeze(CollectionConnection);

const CollectionEdge = {
  "name": "CollectionEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Collection"
  },
  "implementsNode": false
};
Object.freeze(CollectionEdge.fieldBaseTypes);
var CollectionEdge$1 = Object.freeze(CollectionEdge);

const Collection = {
  "name": "Collection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "descriptionHtml": "HTML",
    "handle": "String",
    "id": "ID",
    "image": "Image",
    "products": "ProductConnection",
    "title": "String",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
Object.freeze(Collection.fieldBaseTypes);
var Collection$1 = Object.freeze(Collection);

const HTML = {
  "name": "HTML",
  "kind": "SCALAR"
};
var HTML$1 = Object.freeze(HTML);

const DateTime = {
  "name": "DateTime",
  "kind": "SCALAR"
};
var DateTime$1 = Object.freeze(DateTime);

  const MetafieldConnection = {
    "name": "MetafieldConnection",
    "kind": "OBJECT",
    "fieldBaseTypes": {
      "edges": "MetafieldEdge",
      "pageInfo": "PageInfo"
    },
    "implementsNode": false
  };
    Object.freeze(MetafieldConnection.fieldBaseTypes);
  var MetafieldConnection$1 = Object.freeze(MetafieldConnection);

  const MetafieldEdge = {
    "name": "MetafieldEdge",
    "kind": "OBJECT",
    "fieldBaseTypes": {
      "cursor": "String",
      "node": "Metafield"
    },
    "implementsNode": false
  };
  Object.freeze(MetafieldEdge.fieldBaseTypes);
  var MetafieldEdge$1 = Object.freeze(MetafieldEdge);

  const Metafield = {
    "name": "Metafield",
    "kind": "OBJECT",
    "fieldBaseTypes": {
      "key": "String",
      "value": "String",
      "edges": "MetafieldEdge",
      "pageInfo": "PageInfo"
    },
    "implementsNode": true
  };
  Object.freeze(Metafield.fieldBaseTypes);
  var Metafield$1 = Object.freeze(Metafield);

  const ProductConnection = {
    "name": "ProductConnection",
    "kind": "OBJECT",
    "fieldBaseTypes": {
      "id": "ID",
      "node": "Node",
      "edges": "ProductEdge",
      "pageInfo": "PageInfo"
    },
    "implementsNode": false
  };
  Object.freeze(ProductConnection.fieldBaseTypes);
  var ProductConnection$1 = Object.freeze(ProductConnection);

  const CustomerConnection = {
    "name": "CustomerConnection",
    "kind": "OBJECT",
    "fieldBaseTypes": {
      "id": "ID",
      "node": "Node",
      "edges": "CustomerEdge",
      "pageInfo": "PageInfo"
    },
    "implementsNode": false
  };
  Object.freeze(CustomerConnection.fieldBaseTypes);
  var CustomerConnection$1 = Object.freeze(CustomerConnection);

  const CustomerEdge = {
    "name": "CustomerEdge",
    "kind": "OBJECT",
    "fieldBaseTypes": {
      "cursor": "String",
      "node": "Customer"
    },
    "implementsNode": false
  };
  Object.freeze(CustomerEdge.fieldBaseTypes);
  var CustomerEdge$1 = Object.freeze(CustomerEdge);

const ProductEdge = {
  "name": "ProductEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Product"
  },
  "implementsNode": false
};
Object.freeze(ProductEdge.fieldBaseTypes);
var ProductEdge$1 = Object.freeze(ProductEdge);

const Node = {
  "name": "Node",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "id": "ID",
    "collections": "CollectionConnection",
    "createdAt": "DateTime",
    "description": "String",
    "descriptionHtml": "HTML",
    "handle": "String",
    "images": "ImageConnection",
    "metafields": "MetafieldConnection",
    "options": "ProductOption",
    "productType": "String",
    "publishedAt": "DateTime",
    "tags": "String",
    "title": "String",
    "updatedAt": "DateTime",
    "variantBySelectedOptions": "ProductVariant",
    "variants": "ProductVariantConnection",
    "vendor": "String"

  },
  "possibleTypes": ["AppliedGiftCard", "Checkout", "CheckoutLineItem", "Collection", "MailingAddress", "Order", "Payment", "Product", "ProductOption", "ProductVariant", "ShopPolicy"]
};
Object.freeze(Node.fieldBaseTypes);
Object.freeze(Node.possibleTypes);
var Node$1 = Object.freeze(Node);

const ImageConnection = {
  "name": "ImageConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ImageEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(ImageConnection.fieldBaseTypes);
var ImageConnection$1 = Object.freeze(ImageConnection);

const ImageEdge = {
  "name": "ImageEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Image"
  },
  "implementsNode": false
};
Object.freeze(ImageEdge.fieldBaseTypes);
var ImageEdge$1 = Object.freeze(ImageEdge);

const SelectedOptionInput = {
  "name": "SelectedOptionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "value": "String"
  }
};
Object.freeze(SelectedOptionInput.inputFieldBaseTypes);
var SelectedOptionInput$1 = Object.freeze(SelectedOptionInput);

const ProductOption = {
  "name": "ProductOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "values": "String"
  },
  "implementsNode": true
};
Object.freeze(ProductOption.fieldBaseTypes);
var ProductOption$1 = Object.freeze(ProductOption);

const ProductVariantConnection = {
  "name": "ProductVariantConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ProductVariantEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(ProductVariantConnection.fieldBaseTypes);
var ProductVariantConnection$1 = Object.freeze(ProductVariantConnection);

const ProductVariantEdge = {
  "name": "ProductVariantEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "ProductVariant"
  },
  "implementsNode": false
};
Object.freeze(ProductVariantEdge.fieldBaseTypes);
var ProductVariantEdge$1 = Object.freeze(ProductVariantEdge);

const Attribute = {
  "name": "Attribute",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "value": "String"
  },
  "implementsNode": false
};
Object.freeze(Attribute.fieldBaseTypes);
var Attribute$1 = Object.freeze(Attribute);

const MailingAddress = {
  "name": "MailingAddress",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "company": "String",
    "country": "String",
    "countryCode": "String",
    "firstName": "String",
    "formatted": "String",
    "id": "ID",
    "lastName": "String",
    "latitude": "Float",
    "longitude": "Float",
    "name": "String",
    "phone": "String",
    "province": "String",
    "provinceCode": "String",
    "zip": "String",
  },
  "implementsNode": true
};
Object.freeze(MailingAddress.fieldBaseTypes);
var MailingAddress$1 = Object.freeze(MailingAddress);

const MaxVariantPrice = {
  "name": "MaxVariantPrice",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Decimal",
    "currencyCode": "CurrencyCode"
  },
  "implementsNode": false
}

Object.freeze(MaxVariantPrice.fieldBaseTypes);
var MaxVariantPrice$1 = Object.freeze(MaxVariantPrice);

const MinVariantPrice = {
  "name": "MinVariantPrice",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Decimal",
    "currencyCode": "CurrencyCode"
  },
  "implementsNode": false
}

Object.freeze(MinVariantPrice.fieldBaseTypes);
var MinVariantPrice$1 = Object.freeze(MinVariantPrice);


const Price = {
  "name": "Price",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Decimal",
    "currencyCode": "CurrencyCode"
  },
  "implementsNode": false
}

Object.freeze(Price.fieldBaseTypes);
var Price$1 = Object.freeze(Price);

const PriceRangeConnection = {
  "name": "PriceRangeConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "minVariantPrice": "MinVariantPrice",
    "maxVariantPrice": "MaxVariantPrice"
  },
  "implementsNode": false
};
Object.freeze(PriceRangeConnection.fieldBaseTypes);
var PriceRangeConnection$1 = Object.freeze(PriceRangeConnection);

const ShippingRate = {
  "name": "ShippingRate",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "handle": "String",
    "priceRange": "PriceRangeConnection",
    "title": "String"
  },
  "implementsNode": false
};
Object.freeze(ShippingRate.fieldBaseTypes);
var ShippingRate$1 = Object.freeze(ShippingRate);

const AvailableShippingRates = {
  "name": "AvailableShippingRates",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "ready": "Boolean",
    "shippingRates": "ShippingRate"
  },
  "implementsNode": false
};
Object.freeze(AvailableShippingRates.fieldBaseTypes);
var AvailableShippingRates$1 = Object.freeze(AvailableShippingRates);

const Customer = {
  "name": "Customer",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "acceptsMarketing": "Boolean",
    "addresses": "MailingAddressConnection",
    "createdAt": "DateTime",
    "defaultAddress": "MailingAddress",
    "displayName": "String",
    "email": "String",
    "firstName": "String",
    "id": "ID",
    "lastName": "String",
    "orders": "OrderConnection",
    "phone": "String",
    "updatedAt": "DateTime",
    "tags": ["String"]
  },
  "implementsNode": false
};
Object.freeze(Customer.fieldBaseTypes);
var Customer$1 = Object.freeze(Customer);

const MailingAddressConnection = {
  "name": "MailingAddressConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MailingAddressEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(MailingAddressConnection.fieldBaseTypes);
var MailingAddressConnection$1 = Object.freeze(MailingAddressConnection);

const MailingAddressEdge = {
  "name": "MailingAddressEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "MailingAddress"
  },
  "implementsNode": false
};
Object.freeze(MailingAddressEdge.fieldBaseTypes);
var MailingAddressEdge$1 = Object.freeze(MailingAddressEdge);

const OrderConnection = {
  "name": "OrderConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "OrderEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(OrderConnection.fieldBaseTypes);
var OrderConnection$1 = Object.freeze(OrderConnection);

const OrderEdge = {
  "name": "OrderEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Order"
  },
  "implementsNode": false
};
Object.freeze(OrderEdge.fieldBaseTypes);
var OrderEdge$1 = Object.freeze(OrderEdge);

const Order = {
  "name": "Order",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cancelReason": "OrderCancelReason",
    "cancelledAt": "DateTime",
    "currencyCode": "CurrencyCode",
    "customerUrl": "URL",
    "discountApplications": "DiscountApplicationConnection",
    "fulfillmentStatus": "OrderFulfillmentStatus",
    "email": "String",
    "id": "ID",
    "lineItems": "OrderLineItemConnection",
    "orderNumber": "Int",
    "phone": "String",
    "name": "String",
    "processedAt": "DateTime",
    "shippingAddress": "MailingAddress",
    "subtotalPrice": "Price",
    "totalPrice": "Price",
    "totalRefunded": "MoneyV2",
    "totalShippingPriceV2": "MoneyV2",
    "totalTax": "MoneyV2",
    "updatedAt": "DateTime",
    "totalPrice": "Price",
    "subtotalPrice": "Price",
    "totalTax": "MoneyV2",
    "totalSubtotalPrice": "MoneyV2",
  },
  "implementsNode": true
};
Object.freeze(Order.fieldBaseTypes);
var Order$1 = Object.freeze(Order);

const OrderCancelReason = {
  "name": "OrderCancelReason",
  "kind": "ENUM"
};
var OrderCancelReason$1 = Object.freeze(OrderCancelReason);

const CurrencyCode = {
  "name": "CurrencyCode",
  "kind": "ENUM"
};
var CurrencyCode$1 = Object.freeze(CurrencyCode);

const OrderDisplayFulfillmentStatus = {
  "name": "OrderDisplayFulfillmentStatus",
  "kind": "ENUM"
};
var OrderDisplayFulfillmentStatus$1 = Object.freeze(OrderDisplayFulfillmentStatus);

const OrderDisplayFinancialStatus = {
  "name": "OrderDisplayFinancialStatus",
  "kind": "ENUM"
};
var OrderDisplayFinancialStatus$1 = Object.freeze(OrderDisplayFinancialStatus);

const OrderFulfillmentStatus = {
  "name": "OrderFulfillmentStatus",
  "kind": "ENUM"
};
var OrderFulfillmentStatus$1 = Object.freeze(OrderFulfillmentStatus);

const OrderLineItemConnection = {
  "name": "OrderLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "OrderLineItemEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(OrderLineItemConnection.fieldBaseTypes);
var OrderLineItemConnection$1 = Object.freeze(OrderLineItemConnection);

const OrderLineItemEdge = {
  "name": "OrderLineItemEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "OrderLineItem"
  },
  "implementsNode": false
};
Object.freeze(OrderLineItemEdge.fieldBaseTypes);
var OrderLineItemEdge$1 = Object.freeze(OrderLineItemEdge);

const OrderLineItem = {
  "name": "OrderLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customAttributes": "Attribute",
    "currentQuantity": "Int",
    "title": "String",
    "originalTotalPrice": "MoneyV2",
    "discountedTotalPrice": "MoneyV2",
    "variant": "ProductVariant"
  },
  "implementsNode": false
};
Object.freeze(OrderLineItem.fieldBaseTypes);
var OrderLineItem$1 = Object.freeze(OrderLineItem);

const DiscountApplicationConnection = {
  "name": "DiscountApplicationConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "DiscountApplicationEdge",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
Object.freeze(DiscountApplicationConnection.fieldBaseTypes);
var DiscountApplicationConnection$1 = Object.freeze(DiscountApplicationConnection);

const DiscountApplicationEdge = {
  "name": "DiscountApplicationEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "DiscountApplications"
  },
  "implementsNode": false
};
Object.freeze(DiscountApplicationEdge.fieldBaseTypes);
var DiscountApplicationEdge$1 = Object.freeze(DiscountApplicationEdge);

const DiscountApplications = {
  "name": "DiscountApplications",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "value": "MoneyV2",
  },
  "implementsNode": false
};
Object.freeze(OrderLineItem.fieldBaseTypes);
var DiscountApplications$1 = Object.freeze(DiscountApplications);

const OrderSortKeys = {
  "name": "OrderSortKeys",
  "kind": "ENUM"
};
var OrderSortKeys$1 = Object.freeze(OrderSortKeys);

const AppliedGiftCard = {
  "name": "AppliedGiftCard",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amountUsed": "MoneyV2",
    "balance": "MoneyV2",
    "id": "ID",
    "lastCharacters": "String"
  },
  "implementsNode": true
};
Object.freeze(AppliedGiftCard.fieldBaseTypes);
var AppliedGiftCard$1 = Object.freeze(AppliedGiftCard);

const QueryRoot = {
  "name": "QueryRoot",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "collectionByHandle": ["Collection"],
    "pageByHandle": ["Page"],
    "customer": "Customer",
    "customers": "Customers",
    "node": "Node",
    "nodes": "Node",
    "shop": "Shop",
    "products": "Products",
    "collections": "Collections",
    "productByHandle": "Product"
  },
  "implementsNode": false
};
Object.freeze(QueryRoot.fieldBaseTypes);
var QueryRoot$1 = Object.freeze(QueryRoot);

const Shop = {
  "name": "Shop",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "collections": "CollectionConnection",
    "currencyCode": "CurrencyCode",
    "description": "String",
    "moneyFormat": "String",
    "name": "String",
    "primaryDomain": "Domain",
    "privacyPolicy": "ShopPolicy",
    "products": "ProductConnection",
    "refundPolicy": "ShopPolicy",
    "termsOfService": "ShopPolicy"
  },
  "implementsNode": false
};
Object.freeze(Shop.fieldBaseTypes);
var Shop$1 = Object.freeze(Shop);

const Domain = {
  "name": "Domain",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "host": "String",
    "sslEnabled": "Boolean",
    "url": "URL"
  },
  "implementsNode": false
};
Object.freeze(Domain.fieldBaseTypes);
var Domain$1 = Object.freeze(Domain);

const ShopPolicy = {
  "name": "ShopPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "body": "String",
    "id": "ID",
    "title": "String",
    "url": "URL"
  },
  "implementsNode": true
};
Object.freeze(ShopPolicy.fieldBaseTypes);
var ShopPolicy$1 = Object.freeze(ShopPolicy);

const CollectionSortKeys = {
  "name": "CollectionSortKeys",
  "kind": "ENUM"
};
var CollectionSortKeys$1 = Object.freeze(CollectionSortKeys);

const ProductSortKeys = {
  "name": "ProductSortKeys",
  "kind": "ENUM"
};
var ProductSortKeys$1 = Object.freeze(ProductSortKeys);

const Mutation = {
  "name": "Mutation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutAttributesUpdate": "CheckoutAttributesUpdatePayload",
    "checkoutCompleteFree": "CheckoutCompleteFreePayload",
    "checkoutCompleteWithCreditCard": "CheckoutCompleteWithCreditCardPayload",
    "checkoutCompleteWithTokenizedPayment": "CheckoutCompleteWithTokenizedPaymentPayload",
    "checkoutCreate": "CheckoutCreatePayload",
    "cartCreate": "CartCreatePayload",
    "cartBuyerIdentityUpdate": "CartBuyerIdentityUpdatePayload",
    "checkoutCustomerAssociate": "CheckoutCustomerAssociatePayload",
    "checkoutCustomerAssociateV2": "CheckoutCustomerAssociatePayload",
    "checkoutCustomerDisassociate": "CheckoutCustomerDisassociatePayload",
    "checkoutEmailUpdate": "CheckoutEmailUpdatePayload",
    "checkoutGiftCardApply": "CheckoutGiftCardApplyPayload",
    "checkoutGiftCardRemove": "CheckoutGiftCardRemovePayload",
    "checkoutLineItemsAdd": "CheckoutLineItemsAddPayload",
    "checkoutLineItemsRemove": "CheckoutLineItemsRemovePayload",
    "checkoutLineItemsUpdate": "CheckoutLineItemsUpdatePayload",
    "checkoutShippingAddressUpdate": "CheckoutShippingAddressUpdatePayload",
    "checkoutShippingLineUpdate": "CheckoutShippingLineUpdatePayload",
    "customerAccessTokenCreate": "CustomerAccessTokenCreatePayload",
    "customerAccessTokenDelete": "CustomerAccessTokenDeletePayload",
    "customerAccessTokenRenew": "CustomerAccessTokenRenewPayload",
    "customerActivate": "CustomerActivatePayload",
    "customerAddressCreate": "CustomerAddressCreatePayload",
    "customerAddressDelete": "CustomerAddressDeletePayload",
    "customerAddressUpdate": "CustomerAddressUpdatePayload",
    "customerDefaultAddressUpdate": "customerDefaultAddressUpdatePayload",
    "customerCreate": "CustomerCreatePayload",
    "customerRecover": "CustomerRecoverPayload",
    "customerReset": "CustomerResetPayload",
    "customerUpdate": "CustomerUpdatePayload"
  },
  "implementsNode": false,
  "relayInputObjectBaseTypes": {
    "checkoutAttributesUpdate": "CheckoutAttributesUpdateInput",
    "checkoutCreate": "CheckoutCreateInput",
    "customerAccessTokenCreate": "CustomerAccessTokenCreateInput",
    "customerActivate": "CustomerActivateInput",
    "customerCreate": "CustomerCreateInput",
    "customerReset": "CustomerResetInput"
  }
};
Object.freeze(Mutation.fieldBaseTypes);
Object.freeze(Mutation.relayInputObjectBaseTypes);
var Mutation$1 = Object.freeze(Mutation);

const CheckoutAttributesUpdatePayload = {
  "name": "CheckoutAttributesUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutAttributesUpdatePayload.fieldBaseTypes);
var CheckoutAttributesUpdatePayload$1 = Object.freeze(CheckoutAttributesUpdatePayload);

const CustomerUserError = {
  "name": "CustomerUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "String",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
Object.freeze(CustomerUserError.fieldBaseTypes);
var CustomerUserError$1 = Object.freeze(CustomerUserError);

const UserError = {
  "name": "UserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
Object.freeze(UserError.fieldBaseTypes);
var UserError$1 = Object.freeze(UserError);

const CheckoutAttributesUpdateInput = {
  "name": "CheckoutAttributesUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "note": "String",
    "customAttributes": "AttributeInput",
    "allowPartialAddresses": "Boolean"
  }
};
Object.freeze(CheckoutAttributesUpdateInput.inputFieldBaseTypes);
var CheckoutAttributesUpdateInput$1 = Object.freeze(CheckoutAttributesUpdateInput);

const AttributeInput = {
  "name": "AttributeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "key": "String",
    "value": "String"
  }
};
Object.freeze(AttributeInput.inputFieldBaseTypes);
var AttributeInput$1 = Object.freeze(AttributeInput);

const CheckoutCompleteFreePayload = {
  "name": "CheckoutCompleteFreePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCompleteFreePayload.fieldBaseTypes);
var CheckoutCompleteFreePayload$1 = Object.freeze(CheckoutCompleteFreePayload);

const CheckoutCompleteWithCreditCardPayload = {
  "name": "CheckoutCompleteWithCreditCardPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "payment": "Payment",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCompleteWithCreditCardPayload.fieldBaseTypes);
var CheckoutCompleteWithCreditCardPayload$1 = Object.freeze(CheckoutCompleteWithCreditCardPayload);

const Payment = {
  "name": "Payment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Decimal",
    "billingAddress": "MailingAddress",
    "checkout": "Checkout",
    "creditCard": "CreditCard",
    "errorMessage": "String",
    "id": "ID",
    "idempotencyKey": "String",
    "ready": "Boolean",
    "test": "Boolean",
    "transaction": "Transaction"
  },
  "implementsNode": true
};
Object.freeze(Payment.fieldBaseTypes);
var Payment$1 = Object.freeze(Payment);

const CreditCard = {
  "name": "CreditCard",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "brand": "String",
    "expiryMonth": "Int",
    "expiryYear": "Int",
    "firstDigits": "String",
    "firstName": "String",
    "lastDigits": "String",
    "lastName": "String",
    "maskedNumber": "String"
  },
  "implementsNode": false
};
Object.freeze(CreditCard.fieldBaseTypes);
var CreditCard$1 = Object.freeze(CreditCard);

const Transaction = {
  "name": "Transaction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Decimal",
    "kind": "TransactionKind",
    "status": "TransactionStatus",
    "test": "Boolean"
  },
  "implementsNode": false
};
Object.freeze(Transaction.fieldBaseTypes);
var Transaction$1 = Object.freeze(Transaction);

const TransactionKind = {
  "name": "TransactionKind",
  "kind": "ENUM"
};
var TransactionKind$1 = Object.freeze(TransactionKind);

const TransactionStatus = {
  "name": "TransactionStatus",
  "kind": "ENUM"
};
var TransactionStatus$1 = Object.freeze(TransactionStatus);

const CreditCardPaymentInput = {
  "name": "CreditCardPaymentInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "amount": "Decimal",
    "idempotencyKey": "String",
    "billingAddress": "MailingAddressInput",
    "vaultId": "String",
    "test": "Boolean"
  }
};
Object.freeze(CreditCardPaymentInput.inputFieldBaseTypes);
var CreditCardPaymentInput$1 = Object.freeze(CreditCardPaymentInput);

const MailingAddressInput = {
  "name": "MailingAddressInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "company": "String",
    "country": "String",
    "firstName": "String",
    "lastName": "String",
    "phone": "String",
    "province": "String",
    "zip": "String"
  }
};
Object.freeze(MailingAddressInput.inputFieldBaseTypes);
var MailingAddressInput$1 = Object.freeze(MailingAddressInput);

const CheckoutCompleteWithTokenizedPaymentPayload = {
  "name": "CheckoutCompleteWithTokenizedPaymentPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "payment": "Payment",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCompleteWithTokenizedPaymentPayload.fieldBaseTypes);
var CheckoutCompleteWithTokenizedPaymentPayload$1 = Object.freeze(CheckoutCompleteWithTokenizedPaymentPayload);

const TokenizedPaymentInput = {
  "name": "TokenizedPaymentInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "amount": "Decimal",
    "idempotencyKey": "String",
    "billingAddress": "MailingAddressInput",
    "type": "String",
    "paymentData": "String",
    "test": "Boolean",
    "identifier": "String"
  }
};
Object.freeze(TokenizedPaymentInput.inputFieldBaseTypes);
var TokenizedPaymentInput$1 = Object.freeze(TokenizedPaymentInput);

const CheckoutCreatePayload = {
  "name": "CheckoutCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError",
    "checkoutUserErrors": "CustomerUserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCreatePayload.fieldBaseTypes);
var CheckoutCreatePayload$1 = Object.freeze(CheckoutCreatePayload);

const CheckoutCreateInput = {
  "name": "CheckoutCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "email": "String",
    "lineItems": "CheckoutLineItemInput",
    "shippingAddress": "MailingAddressInput",
    "note": "String",
    "customAttributes": "AttributeInput",
    "allowPartialAddresses": "Boolean"
  }
};
Object.freeze(CheckoutCreateInput.inputFieldBaseTypes);
var CheckoutCreateInput$1 = Object.freeze(CheckoutCreateInput);

const CheckoutLineItemInput = {
  "name": "CheckoutLineItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "variantId": "ID",
    "quantity": "Int",
    "customAttributes": "AttributeInput"
  }
};
Object.freeze(CheckoutLineItemInput.inputFieldBaseTypes);
var CheckoutLineItemInput$1 = Object.freeze(CheckoutLineItemInput);

const CheckoutCustomerAssociatePayload = {
  "name": "CheckoutCustomerAssociatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCustomerAssociatePayload.fieldBaseTypes);
var CheckoutCustomerAssociatePayload$1 = Object.freeze(CheckoutCustomerAssociatePayload);

const CheckoutCustomerDisassociatePayload = {
  "name": "CheckoutCustomerDisassociatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutCustomerDisassociatePayload.fieldBaseTypes);
var CheckoutCustomerDisassociatePayload$1 = Object.freeze(CheckoutCustomerDisassociatePayload);

const CheckoutEmailUpdatePayload = {
  "name": "CheckoutEmailUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutEmailUpdatePayload.fieldBaseTypes);
var CheckoutEmailUpdatePayload$1 = Object.freeze(CheckoutEmailUpdatePayload);

const CheckoutGiftCardApplyPayload = {
  "name": "CheckoutGiftCardApplyPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutGiftCardApplyPayload.fieldBaseTypes);
var CheckoutGiftCardApplyPayload$1 = Object.freeze(CheckoutGiftCardApplyPayload);

const CheckoutGiftCardRemovePayload = {
  "name": "CheckoutGiftCardRemovePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutGiftCardRemovePayload.fieldBaseTypes);
var CheckoutGiftCardRemovePayload$1 = Object.freeze(CheckoutGiftCardRemovePayload);

const CheckoutLineItemsAddPayload = {
  "name": "CheckoutLineItemsAddPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutLineItemsAddPayload.fieldBaseTypes);
var CheckoutLineItemsAddPayload$1 = Object.freeze(CheckoutLineItemsAddPayload);

const CheckoutLineItemsRemovePayload = {
  "name": "CheckoutLineItemsRemovePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutLineItemsRemovePayload.fieldBaseTypes);
var CheckoutLineItemsRemovePayload$1 = Object.freeze(CheckoutLineItemsRemovePayload);

const CheckoutLineItemsUpdatePayload = {
  "name": "CheckoutLineItemsUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutLineItemsUpdatePayload.fieldBaseTypes);
var CheckoutLineItemsUpdatePayload$1 = Object.freeze(CheckoutLineItemsUpdatePayload);

const CheckoutLineItemUpdateInput = {
  "name": "CheckoutLineItemUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "variantId": "ID",
    "quantity": "Int",
    "customAttributes": "AttributeInput"
  }
};
Object.freeze(CheckoutLineItemUpdateInput.inputFieldBaseTypes);
var CheckoutLineItemUpdateInput$1 = Object.freeze(CheckoutLineItemUpdateInput);

const CheckoutShippingAddressUpdatePayload = {
  "name": "CheckoutShippingAddressUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutShippingAddressUpdatePayload.fieldBaseTypes);
var CheckoutShippingAddressUpdatePayload$1 = Object.freeze(CheckoutShippingAddressUpdatePayload);

const CheckoutShippingLineUpdatePayload = {
  "name": "CheckoutShippingLineUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CheckoutShippingLineUpdatePayload.fieldBaseTypes);
var CheckoutShippingLineUpdatePayload$1 = Object.freeze(CheckoutShippingLineUpdatePayload);

const CustomerAccessTokenCreatePayload = {
  "name": "CustomerAccessTokenCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerAccessToken": "CustomerAccessToken",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerAccessTokenCreatePayload.fieldBaseTypes);
var CustomerAccessTokenCreatePayload$1 = Object.freeze(CustomerAccessTokenCreatePayload);

const CustomerAccessToken = {
  "name": "CustomerAccessToken",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accessToken": "String",
    "expiresAt": "DateTime"
  },
  "implementsNode": false
};
Object.freeze(CustomerAccessToken.fieldBaseTypes);
var CustomerAccessToken$1 = Object.freeze(CustomerAccessToken);

const CustomerAccessTokenCreateInput = {
  "name": "CustomerAccessTokenCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "email": "String",
    "password": "String"
  }
};
Object.freeze(CustomerAccessTokenCreateInput.inputFieldBaseTypes);
var CustomerAccessTokenCreateInput$1 = Object.freeze(CustomerAccessTokenCreateInput);

const CustomerAccessTokenDeletePayload = {
  "name": "CustomerAccessTokenDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedAccessToken": "String",
    "deletedCustomerAccessTokenId": "String",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerAccessTokenDeletePayload.fieldBaseTypes);
var CustomerAccessTokenDeletePayload$1 = Object.freeze(CustomerAccessTokenDeletePayload);

const CustomerAccessTokenRenewPayload = {
  "name": "CustomerAccessTokenRenewPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerAccessToken": "CustomerAccessToken",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerAccessTokenRenewPayload.fieldBaseTypes);
var CustomerAccessTokenRenewPayload$1 = Object.freeze(CustomerAccessTokenRenewPayload);

const CustomerActivatePayload = {
  "name": "CustomerActivatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerActivatePayload.fieldBaseTypes);
var CustomerActivatePayload$1 = Object.freeze(CustomerActivatePayload);

const CustomerActivateInput = {
  "name": "CustomerActivateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "activationToken": "String",
    "password": "String"
  }
};
Object.freeze(CustomerActivateInput.inputFieldBaseTypes);
var CustomerActivateInput$1 = Object.freeze(CustomerActivateInput);

const CustomerAddressCreatePayload = {
  "name": "CustomerAddressCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerAddress": "MailingAddress",
    "userErrors": "UserError",
    "customerUserErrors": "CustomerUserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerAddressCreatePayload.fieldBaseTypes);
var CustomerAddressCreatePayload$1 = Object.freeze(CustomerAddressCreatePayload);

const CustomerAddressDeletePayload = {
  "name": "CustomerAddressDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedCustomerAddressId": "String",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerAddressDeletePayload.fieldBaseTypes);
var CustomerAddressDeletePayload$1 = Object.freeze(CustomerAddressDeletePayload);

const CustomerAddressUpdatePayload = {
  "name": "CustomerAddressUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerAddress": "MailingAddress",
    "userErrors": "UserError",
    "customerUserErrors": "CustomerUserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerAddressUpdatePayload.fieldBaseTypes);
var CustomerAddressUpdatePayload$1 = Object.freeze(CustomerAddressUpdatePayload);

const CustomerDefaultAddressUpdatePayload = {
  "name": "CustomerDefaultAddressUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "userErrors": "UserError",
    "customerUserErrors": "CustomerUserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerDefaultAddressUpdatePayload.fieldBaseTypes);
var CustomerDefaultAddressUpdatePayload$1 = Object.freeze(CustomerDefaultAddressUpdatePayload);

const CustomerCreatePayload = {
  "name": "CustomerCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "customerUserErrors": "CustomerUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerCreatePayload.fieldBaseTypes);
var CustomerCreatePayload$1 = Object.freeze(CustomerCreatePayload);

const CustomerCreateInput = {
  "name": "CustomerCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "firstName": "String",
    "lastName": "String",
    "email": "String",
    "password": "String",
    "acceptsMarketing": "Boolean"
  }
};
Object.freeze(CustomerCreateInput.inputFieldBaseTypes);
var CustomerCreateInput$1 = Object.freeze(CustomerCreateInput);

const CustomerRecoverPayload = {
  "name": "CustomerRecoverPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerUserErrors": "CustomerUserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerRecoverPayload.fieldBaseTypes);
var CustomerRecoverPayload$1 = Object.freeze(CustomerRecoverPayload);

const CustomerResetPayload = {
  "name": "CustomerResetPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerResetPayload.fieldBaseTypes);
var CustomerResetPayload$1 = Object.freeze(CustomerResetPayload);

const CustomerResetInput = {
  "name": "CustomerResetInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "resetToken": "String",
    "password": "String"
  }
};
Object.freeze(CustomerResetInput.inputFieldBaseTypes);
var CustomerResetInput$1 = Object.freeze(CustomerResetInput);

const CustomerUpdatePayload = {
  "name": "CustomerUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "userErrors": "UserError",
    "customerAccessToken": "CustomerAccessToken",
    "customerUserErrors": "CustomerUserError"
  },
  "implementsNode": false
};
Object.freeze(CustomerUpdatePayload.fieldBaseTypes);
var CustomerUpdatePayload$1 = Object.freeze(CustomerUpdatePayload);

const CustomerUpdateInput = {
  "name": "CustomerUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "firstName": "String",
    "lastName": "String",
    "email": "String",
    "password": "String",
    "acceptsMarketing": "Boolean"
  }
};
Object.freeze(CustomerUpdateInput.inputFieldBaseTypes);
var CustomerUpdateInput$1 = Object.freeze(CustomerUpdateInput);

const Page = {
  "name": "Page",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    body: "HTML",
    bodySummary: "String",
    createdAt: "DateTime",
    handle: "String",
    id: "ID",
    title: 'String',
    hadnle: 'String',
    updatedAt: 'DateTime',
    url: "URL"
  },
  "implementsNode": true
};
Object.freeze(Page.fieldBaseTypes);
var Page$1 = Object.freeze(Page);

const CartUserError = {
  "name": "CartUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "CartErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};

Object.freeze(CartUserError.fieldBaseTypes);
var CartUserError$1 = Object.freeze(CartUserError);


const CartCreatePayload = {
  "name": "CartCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cart": "Cart",
    "userErrors": "CartUserError"
  },
  "implementsNode": false
};
Object.freeze(CartCreatePayload.fieldBaseTypes);
var CartCreatePayload$1 = Object.freeze(CartCreatePayload);

const CartBuyerIdentityUpdatePayload = {
  "name": "CartBuyerIdentityUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cart": "Cart"
  },
  "implementsNode": false
};
Object.freeze(CartBuyerIdentityUpdatePayload.fieldBaseTypes);
var CartBuyerIdentityUpdatePayload$1 = Object.freeze(CartBuyerIdentityUpdatePayload);

const CartErrorCode = {
  "name": "CartErrorCode",
  "kind": "ENUM"
};
var CartErrorCode$1 = Object.freeze(CartErrorCode);

const Cart = {
  "name": "Cart",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "attribute": "Attribute",
    "attributes": "Attribute",
    "buyerIdentity": "CartBuyerIdentity",
    "checkoutUrl": "URL",
    "cost": "CartCost",
    "createdAt": "DateTime",
    "deliveryGroups": "CartDeliveryGroupConnection",
    "discountAllocations": "CartDiscountAllocation",
    "discountCodes": "CartDiscountCode",
    "estimatedCost": "CartEstimatedCost",
    "id": "ID",
    "lines": "BaseCartLineConnection",
    "metafield": "Metafield",
    "metafields": "Metafield",
    "note": "String",
    "totalQuantity": "Int",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
Object.freeze(Cart.fieldBaseTypes);
var Cart$1 = Object.freeze(Cart);

const CartBuyerIdentity = {
  "name": "CartBuyerIdentity",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countryCode": "CountryCode",
    "customer": "Customer",
    "deliveryAddressPreferences": "DeliveryAddress",
    "email": "String",
    "phone": "String",
    "purchasingCompany": "PurchasingCompany",
    "walletPreferences": "String"
  },
  "implementsNode": false
};
Object.freeze(CartBuyerIdentity.fieldBaseTypes);
var CartBuyerIdentity$1 = Object.freeze(CartBuyerIdentity);


const CountryCode = {
  "name": "CountryCode",
  "kind": "ENUM"
};
var CountryCode$1 = Object.freeze(CountryCode);

const __Schema = {
  "name": "__Schema",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "directives": "__Directive",
    "mutationType": "__Type",
    "queryType": "__Type",
    "subscriptionType": "__Type",
    "types": "__Type"
  },
  "implementsNode": false
};
Object.freeze(__Schema.fieldBaseTypes);
var __Schema$1 = Object.freeze(__Schema);

const __Type = {
  "name": "__Type",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "enumValues": "__EnumValue",
    "fields": "__Field",
    "inputFields": "__InputValue",
    "interfaces": "__Type",
    "kind": "__TypeKind",
    "name": "String",
    "ofType": "__Type",
    "possibleTypes": "__Type"
  },
  "implementsNode": false
};
Object.freeze(__Type.fieldBaseTypes);
var __Type$1 = Object.freeze(__Type);

const __TypeKind = {
  "name": "__TypeKind",
  "kind": "ENUM"
};
var __TypeKind$1 = Object.freeze(__TypeKind);

const __Field = {
  "name": "__Field",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "args": "__InputValue",
    "deprecationReason": "String",
    "description": "String",
    "isDeprecated": "Boolean",
    "name": "String",
    "type": "__Type"
  },
  "implementsNode": false
};
Object.freeze(__Field.fieldBaseTypes);
var __Field$1 = Object.freeze(__Field);

const __InputValue = {
  "name": "__InputValue",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "defaultValue": "String",
    "description": "String",
    "name": "String",
    "type": "__Type"
  },
  "implementsNode": false
};
Object.freeze(__InputValue.fieldBaseTypes);
var __InputValue$1 = Object.freeze(__InputValue);

const __EnumValue = {
  "name": "__EnumValue",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deprecationReason": "String",
    "description": "String",
    "isDeprecated": "Boolean",
    "name": "String"
  },
  "implementsNode": false
};
Object.freeze(__EnumValue.fieldBaseTypes);
var __EnumValue$1 = Object.freeze(__EnumValue);

const __Directive = {
  "name": "__Directive",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "args": "__InputValue",
    "description": "String",
    "locations": "__DirectiveLocation",
    "name": "String",
    "onField": "Boolean",
    "onFragment": "Boolean",
    "onOperation": "Boolean"
  },
  "implementsNode": false
};
Object.freeze(__Directive.fieldBaseTypes);
var __Directive$1 = Object.freeze(__Directive);

const __DirectiveLocation = {
  "name": "__DirectiveLocation",
  "kind": "ENUM"
};
var __DirectiveLocation$1 = Object.freeze(__DirectiveLocation);

const Types = {
  types: {}
};
Types.types["Price"] = Price$1;
Types.types["MaxVariantPrice"] = MaxVariantPrice$1;
Types.types["MinVariantPrice"] = MinVariantPrice$1;
Types.types["PriceRangeConnection"] = PriceRangeConnection$1;
Types.types["CustomerConnection"] = CustomerConnection$1;
Types.types["Customers"] = CustomerConnection$1; 
Types.types["Checkout"] = Checkout$1;
Types.types["ID"] = ID$1;
Types.types["Boolean"] = Boolean$1;
Types.types["CheckoutLineItemConnection"] = CheckoutLineItemConnection$1;
Types.types["PageInfo"] = PageInfo$1;
Types.types["CheckoutLineItemEdge"] = CheckoutLineItemEdge$1;
Types.types["String"] = String$1;
Types.types["CheckoutLineItem"] = CheckoutLineItem$1;
Types.types["ProductVariant"] = ProductVariant$1;
Types.types["Float"] = Float$1;
Types.types["WeightUnit"] = WeightUnit$1;
Types.types["MoneyV2"] = MoneyV2$1;
Types.types["Decimal"] = Decimal$1;
Types.types["Image"] = Image$1;
Types.types["DiscountApplicationConnection"] = DiscountApplicationConnection$1;
Types.types["DiscountApplicationEdge"] = DiscountApplicationEdge$1;
Types.types["DiscountApplications"] = DiscountApplications$1;
Types.types["URL"] = URL$1;
Types.types["Int"] = Int$1;
Types.types["CropRegion"] = CropRegion$1;
Types.types["SelectedOption"] = SelectedOption$1;
Types.types["Product"] = Product$1;
Types.types["CollectionConnection"] = CollectionConnection$1;
Types.types["CollectionEdge"] = CollectionEdge$1;
Types.types["Collection"] = Collection$1;
Types.types["Page"] = Page$1;
Types.types["HTML"] = HTML$1;
Types.types["DateTime"] = DateTime$1;
Types.types["ProductConnection"] = ProductConnection$1;
Types.types["ProductEdge"] = ProductEdge$1;
Types.types["MetafieldConnection"] = MetafieldConnection$1;
Types.types["MetafieldEdge"] = MetafieldEdge$1;
Types.types["Metafield"] = Metafield$1;
Types.types["Node"] = Node$1;
Types.types["ImageConnection"] = ImageConnection$1;
Types.types["ImageEdge"] = ImageEdge$1;
Types.types["SelectedOptionInput"] = SelectedOptionInput$1;
Types.types["ProductOption"] = ProductOption$1;
Types.types["ProductVariantConnection"] = ProductVariantConnection$1;
Types.types["ProductVariantEdge"] = ProductVariantEdge$1;
Types.types["Attribute"] = Attribute$1;
Types.types["MailingAddress"] = MailingAddress$1;
Types.types["ShippingRate"] = ShippingRate$1;
Types.types["AvailableShippingRates"] = AvailableShippingRates$1;
Types.types["Customer"] = Customer$1;
Types.types["MailingAddressConnection"] = MailingAddressConnection$1;
Types.types["MailingAddressEdge"] = MailingAddressEdge$1;
Types.types["OrderConnection"] = OrderConnection$1;
Types.types["OrderEdge"] = OrderEdge$1;
Types.types["Order"] = Order$1;
Types.types["OrderCancelReason"] = OrderCancelReason$1;
Types.types["CurrencyCode"] = CurrencyCode$1;
Types.types["OrderDisplayFulfillmentStatus"] = OrderDisplayFulfillmentStatus$1;
Types.types["OrderDisplayFinancialStatus"] = OrderDisplayFinancialStatus$1;
Types.types["OrderFulfillmentStatus"] = OrderFulfillmentStatus$1;
Types.types["OrderLineItemConnection"] = OrderLineItemConnection$1;
Types.types["OrderLineItemEdge"] = OrderLineItemEdge$1;
Types.types["OrderLineItem"] = OrderLineItem$1;
Types.types["OrderSortKeys"] = OrderSortKeys$1;
Types.types["AppliedGiftCard"] = AppliedGiftCard$1;
Types.types["QueryRoot"] = QueryRoot$1;
Types.types["Shop"] = Shop$1;
Types.types["Products"] = ProductConnection$1;
Types.types["Collections"] = CollectionConnection$1;
Types.types["Domain"] = Domain$1;
Types.types["ShopPolicy"] = ShopPolicy$1;
Types.types["CollectionSortKeys"] = CollectionSortKeys$1;
Types.types["ProductSortKeys"] = ProductSortKeys$1;
Types.types["Mutation"] = Mutation$1;
Types.types["CheckoutAttributesUpdatePayload"] = CheckoutAttributesUpdatePayload$1;
Types.types["CustomerUserError"] = CustomerUserError$1;
Types.types["UserError"] = UserError$1;
Types.types["CheckoutAttributesUpdateInput"] = CheckoutAttributesUpdateInput$1;
Types.types["AttributeInput"] = AttributeInput$1;
Types.types["CheckoutCompleteFreePayload"] = CheckoutCompleteFreePayload$1;
Types.types["CheckoutCompleteWithCreditCardPayload"] = CheckoutCompleteWithCreditCardPayload$1;
Types.types["Payment"] = Payment$1;
Types.types["CreditCard"] = CreditCard$1;
Types.types["Transaction"] = Transaction$1;
Types.types["TransactionKind"] = TransactionKind$1;
Types.types["TransactionStatus"] = TransactionStatus$1;
Types.types["CreditCardPaymentInput"] = CreditCardPaymentInput$1;
Types.types["MailingAddressInput"] = MailingAddressInput$1;
Types.types["CheckoutCompleteWithTokenizedPaymentPayload"] = CheckoutCompleteWithTokenizedPaymentPayload$1;
Types.types["TokenizedPaymentInput"] = TokenizedPaymentInput$1;
Types.types["CheckoutCreatePayload"] = CheckoutCreatePayload$1;
Types.types["Cart"] =  Cart$1;
Types.types["CartUserError"] = CartUserError$1;
Types.types["CartErrorCode"] = CartErrorCode$1;
Types.types["CartCreatePayload"] = CartCreatePayload$1;
Types.types["CartBuyerIdentityUpdatePayload"] = CartBuyerIdentityUpdatePayload$1;
Types.types["CartBuyerIdentity"] = CartBuyerIdentity$1;
Types.types["CountryCode"] = CountryCode$1;
Types.types["CheckoutCreateInput"] = CheckoutCreateInput$1;
Types.types["CheckoutLineItemInput"] = CheckoutLineItemInput$1;
Types.types["CheckoutCustomerAssociatePayload"] = CheckoutCustomerAssociatePayload$1;
Types.types["CheckoutCustomerDisassociatePayload"] = CheckoutCustomerDisassociatePayload$1;
Types.types["CheckoutEmailUpdatePayload"] = CheckoutEmailUpdatePayload$1;
Types.types["CheckoutGiftCardApplyPayload"] = CheckoutGiftCardApplyPayload$1;
Types.types["CheckoutGiftCardRemovePayload"] = CheckoutGiftCardRemovePayload$1;
Types.types["CheckoutLineItemsAddPayload"] = CheckoutLineItemsAddPayload$1;
Types.types["CheckoutLineItemsRemovePayload"] = CheckoutLineItemsRemovePayload$1;
Types.types["CheckoutLineItemsUpdatePayload"] = CheckoutLineItemsUpdatePayload$1;
Types.types["CheckoutLineItemUpdateInput"] = CheckoutLineItemUpdateInput$1;
Types.types["CheckoutShippingAddressUpdatePayload"] = CheckoutShippingAddressUpdatePayload$1;
Types.types["CheckoutShippingLineUpdatePayload"] = CheckoutShippingLineUpdatePayload$1;
Types.types["CustomerAccessTokenCreatePayload"] = CustomerAccessTokenCreatePayload$1;
Types.types["CustomerAccessToken"] = CustomerAccessToken$1;
Types.types["CustomerAccessTokenCreateInput"] = CustomerAccessTokenCreateInput$1;
Types.types["CustomerAccessTokenDeletePayload"] = CustomerAccessTokenDeletePayload$1;
Types.types["CustomerAccessTokenRenewPayload"] = CustomerAccessTokenRenewPayload$1;
Types.types["CustomerActivatePayload"] = CustomerActivatePayload$1;
Types.types["CustomerActivateInput"] = CustomerActivateInput$1;
Types.types["CustomerAddressCreatePayload"] = CustomerAddressCreatePayload$1;
Types.types["CustomerAddressDeletePayload"] = CustomerAddressDeletePayload$1;
Types.types["CustomerAddressUpdatePayload"] = CustomerAddressUpdatePayload$1;
Types.types["customerDefaultAddressUpdatePayload"] = CustomerDefaultAddressUpdatePayload$1;
Types.types["CustomerCreatePayload"] = CustomerCreatePayload$1;
Types.types["CustomerCreateInput"] = CustomerCreateInput$1;
Types.types["CustomerRecoverPayload"] = CustomerRecoverPayload$1;
Types.types["CustomerResetPayload"] = CustomerResetPayload$1;
Types.types["CustomerResetInput"] = CustomerResetInput$1;
Types.types["CustomerUpdatePayload"] = CustomerUpdatePayload$1;
Types.types["CustomerUpdateInput"] = CustomerUpdateInput$1;
Types.types["__Schema"] = __Schema$1;
Types.types["__Type"] = __Type$1;
Types.types["__TypeKind"] = __TypeKind$1;
Types.types["__Field"] = __Field$1;
Types.types["__InputValue"] = __InputValue$1;
Types.types["__EnumValue"] = __EnumValue$1;
Types.types["__Directive"] = __Directive$1;
Types.types["__DirectiveLocation"] = __DirectiveLocation$1;
Types.queryType = "QueryRoot";
Types.mutationType = "Mutation";
Types.subscriptionType = null;
Object.freeze(Types.types);
var types = Object.freeze(Types);

export default types;
